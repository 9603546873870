import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import Home from '../../pages/home/Home';
import Login from '../../pages/authentication/Login';
import NotFound from '../../pages/NotFound';
import Marketplace from '../../pages/marketplace/Marketplace';
import Orders from '../../pages/orders/Orders';
import ForgotPassword from 'src/pages/authentication/ForgotPassword';
import ConfirmEmail from 'src/pages/authentication/ConfirmEmail';
import Onboard from 'src/pages/authentication/onboard/Onboard';
import OrderDetail from 'src/pages/orders/OrderDetail';
import SignUp from 'src/pages/authentication/SignUp';
import ConfirmReset from 'src/pages/authentication/ConfirmReset';
import Intro from 'src/pages/authentication/onboard/Intro';
import ValidDataProtectedRoutes from './ValidDataProtectedRoutes';
import Settings from 'src/pages/profile/Settings';
import UserConfirm from 'src/pages/authentication/UserConfirm';
import ResetPassword from 'src/pages/authentication/ResetPassword';

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<ProtectedRoutes />}>
                    <Route element={<ValidDataProtectedRoutes />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/marketplace" element={<Marketplace />} />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/order/:id" element={<OrderDetail />} />
                        <Route path="/settings" element={<Settings />} />
                    </Route>
                    <Route path="/intro" element={<Intro />} />
                    <Route path="/onboard" element={<Onboard />} />
                </Route>
                <Route path="/sign-in" element={<Login />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/confirm-reset" element={<ConfirmReset />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/confirm-account" element={<ConfirmEmail />} />
                <Route path="/user-confirm" element={<UserConfirm />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
