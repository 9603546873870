import { Dialog } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import ProductsOverviewStep from './components/ProductsOverviewStep';
import ConfirmationStep from './components/ConfirmationStep';
import { StepModel } from './stepper/Stepper.interface';
import { stepList } from './stepper/StepsList';
import CustomStepper from './stepper/CustomStepper';
import xMark from 'src/assets/icons/xmark-regular.svg';
import Summary from './components/Summary';
import { CartContext } from 'src/context/CartContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Product } from 'src/pages/marketplace/Marketplace.interface';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { toast } from 'react-toastify';

type Props = {
    dialogOpen: boolean;
    onClose: (event: any, reason: any) => void;
    setDialogOpen: any;
};

const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
        : ''
);

const CheckoutDialog = ({ dialogOpen, onClose, setDialogOpen }: Props) => {
    const [activeStep, setActiveStep] = useState<number>(1);
    const [steps, setSteps] = useState<Array<StepModel>>(stepList);
    const [subTotalPrice, setSubTotalPrice] = useState<number>(0);
    const [tax, setTax] = useState<number>(0);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const { productList, setProductList } = useContext(CartContext);

    const setCurrentStepValid = (valid: boolean) => {
        let tempList: Array<StepModel> = steps.map((item: StepModel) => {
            if (item.id === activeStep) {
                return {
                    ...item,
                    isValid: valid,
                };
            }
            return {
                ...item,
            };
        });
        setSteps(tempList);
    };

    const onStepChange = (nextStep: number) => {
        let tempList: Array<StepModel> = steps.map((item: StepModel) => {
            let tempStep: StepModel = {
                ...item,
                isActive: false,
            };

            if (activeStep < nextStep && item.id === nextStep - 1) {
                tempStep.isFinished = true;
            }

            if (activeStep >= nextStep && item.id === nextStep) {
                tempStep.isFinished = false;
            }

            if (item.id === nextStep) {
                tempStep.isActive = true;
            }

            return tempStep;
        });

        setActiveStep(nextStep);
        setSteps(tempList);
    };

    const navigateTo = (nextStep: number, currentStepValid?: boolean) => {
        if (currentStepValid) {
            onStepChange(nextStep);
            return;
        }
        let currentStep = steps.find(
            (item: StepModel) => item.id === activeStep
        );

        if (currentStep?.isValid) {
            onStepChange(nextStep);
        }
    };

    useEffect(() => {
        let tempSubtotal = 0;
        productList.forEach((item: Product) => {
            tempSubtotal = item.price;
        });

        let tempTax = tempSubtotal * 0.19;
        setSubTotalPrice(tempSubtotal);
        setTax(tempTax);
        setTotalPrice(tempSubtotal + tempTax);
    }, [productList]);

    const getComponent = (stepId: number) => {
        let component = <div></div>;
        switch (stepId) {
            case 1:
                component = (
                    <ProductsOverviewStep
                        setCurrentStepValid={setCurrentStepValid}
                        steps={steps}
                        activeStep={activeStep}
                        navigateTo={navigateTo}
                        onStepChange={onStepChange}
                        products={productList}
                        setProductList={setProductList}
                    />
                );
                break;
            case 2:
                component = (
                    <ConfirmationStep
                        setCurrentStepValid={setCurrentStepValid}
                        steps={steps}
                        activeStep={activeStep}
                        navigateTo={navigateTo}
                        onStepChange={onStepChange}
                        totalAmount={totalPrice}
                        setMainDialogOpen={setDialogOpen}
                    />
                );
                break;
        }
        return component;
    };

    return (
        <Dialog onClose={onClose} open={dialogOpen} fullWidth maxWidth="xl">
            <div className="flex h-full w-full flex-col gap-10 rounded-2xl bg-[#F8F8F8] p-6">
                <div className="flex justify-between">
                    <CustomStepper steps={steps} />
                    <button
                        className="flex h-8 w-8 items-center justify-center rounded-full bg-[#fff] p-1 "
                        onClick={() => setDialogOpen(false)}
                    >
                        <img src={xMark} className="h-5 w-5" alt="x-mark" />
                    </button>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-col gap-2 md:flex-row">
                        <div className="flex w-full flex-col rounded-3xl bg-[#FFF] px-6 py-10 md:w-4/6">
                            <div className="h-full w-full">
                                <Elements stripe={stripePromise}>
                                    <PayPalScriptProvider
                                        options={{
                                            clientId: process.env
                                                .REACT_APP_PAYPAL_CLIENT_ID
                                                ? process.env
                                                      .REACT_APP_PAYPAL_CLIENT_ID
                                                : '',
                                            currency: 'EUR',
                                        }}
                                    >
                                        {getComponent(activeStep)}
                                    </PayPalScriptProvider>
                                </Elements>
                            </div>
                        </div>
                        <div className="flex w-full flex-col rounded-3xl bg-[#24245f08] px-6 py-10 md:w-2/6">
                            <Summary
                                products={productList}
                                totalPrice={totalPrice}
                                subTotalPrice={subTotalPrice}
                                tax={tax}
                                setSubTotalPrice={setSubTotalPrice}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default CheckoutDialog;
