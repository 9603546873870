import xMark from 'src/assets/icons/xmark-regular.svg';
import checkRounded from 'src/assets/icons/check-rounded.svg';
import { Dialog } from '@mui/material';
import {
    Coach,
    Product,
    ProductFeature,
    ProductType,
} from 'src/pages/marketplace/Marketplace.interface';
import { useTranslation } from 'react-i18next';
import productImg from 'src/assets/images/product-placeholder.png';
import BuyButton from '../buttons/BuyButton';
import calendar from 'src/assets/icons/calendar-green.svg';
import user from 'src/assets/icons/person-green.svg';
import time from 'src/assets/icons/TwotoneAccessTime.svg';
import location from 'src/assets/icons/location-green.svg';
import { LanguageContext } from 'src/context/LanguageContext';
import { useContext } from 'react';

type Props = {
    dialogOpen: boolean;
    onClose: (event: any, reason: any) => void;
    setDialogOpen: any;
    product?: Product;
};

const PackageDetailsDialog = ({
    dialogOpen,
    onClose,
    setDialogOpen,
    product,
}: Props) => {
    const { t } = useTranslation();
    const { currentLanguage } = useContext(LanguageContext);
    return (
        <Dialog onClose={onClose} fullWidth maxWidth="sm" open={dialogOpen}>
            <div className="flex min-w-[250px] flex-col rounded-2xl p-5">
                <div>
                    {product && product.image && (
                        <img
                            className="mx-auto my-5 max-h-56 max-w-5xl"
                            src={product.image}
                            alt="seminar-img"
                        />
                    )}
                    {product && !product.image && (
                        <img
                            className="mx-auto my-5 max-h-56 max-w-5xl"
                            src={productImg}
                            alt="seminar-placeholder"
                        />
                    )}

                    <p className="relative bottom-[50px] left-[15px] w-fit rounded bg-[#fff] px-6 py-3 text-xs text-[#4ABFBF] shadow-lg shadow-[#0012330d]">
                        {product && product.category[0].name}
                    </p>
                    <button
                        className="absolute right-0 top-0 m-2 w-fit rounded-full bg-[#fff] p-1"
                        onClick={() => setDialogOpen(false)}
                    >
                        <img src={xMark} className="h-5 w-5" alt="x-mark" />
                    </button>
                </div>

                <div className="relative bottom-[25px] flex h-full flex-col gap-4 px-6 py-4">
                    <h2 className="text-3xl font-semibold text-[#24245F]">
                        {product && product.title}
                    </h2>
                    <p className="text-base text-[#24245f99]">
                        {product &&
                            t(`page.marketplace.${product.type}`).toUpperCase()}
                    </p>
                    <div className="flex items-center justify-between border-y-2 py-2">
                        <div className="flex">
                            <div className="flex flex-col">
                                <p className="text-2xl font-semibold text-[#4ABFBF]">
                                    € {product && product.price}
                                </p>
                            </div>
                            <p className="mt-auto pb-1 pl-2 text-sm font-normal text-[#24245F]">
                                ({t('checkout.plusVat')})
                            </p>
                        </div>
                        {product && <BuyButton product={product} />}
                    </div>
                    {product && product.short_description && (
                        <div>
                            <p className="m-0 p-0">
                                {product.short_description}
                            </p>
                        </div>
                    )}
                    {product && product.features && (
                        <p className=" border-t-2 py-3 pt-4 text-2xl text-[#24245F]">
                            {t('common.whatYouGet')}
                        </p>
                    )}
                    <div className="flex flex-col gap-7 border-b-2 pb-4">
                        {product &&
                            product.features &&
                            product.features.map((item: ProductFeature) => {
                                return (
                                    <div key={item.ID} className="flex gap-5">
                                        <img
                                            src={checkRounded}
                                            alt="Check"
                                            className="h-8 w-8"
                                        />
                                        <div className="flex flex-col gap-2">
                                            <p className="text-lg font-semibold text-[#24245F]">
                                                {item.post_title}
                                            </p>
                                            <p className="text-sm font-normal">
                                                {item.post_content}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}

                        {product && product.type === ProductType.SEMINAR && (
                            <div className="flex flex-wrap justify-between gap-5 border-y-2 py-4">
                                <div className="flex gap-4">
                                    <img
                                        width={30}
                                        height={30}
                                        alt="Calendar"
                                        src={calendar}
                                    />
                                    <div className="flex flex-col">
                                        <p className="text-sm font-normal text-[#24245f] opacity-80">
                                            {t('page.marketplace.date')}{' '}
                                        </p>
                                        <p className="text-base text-[#24245f]">
                                            {product.date_time
                                                ? new Date(
                                                      product.date_time
                                                  ).toLocaleDateString(
                                                      currentLanguage
                                                  )
                                                : ''}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex gap-4">
                                    <img
                                        width={30}
                                        height={30}
                                        alt="Time"
                                        src={time}
                                    />
                                    <div className="flex flex-col">
                                        <p className="text-sm font-normal text-[#24245f] opacity-80">
                                            {t('page.marketplace.time')}{' '}
                                        </p>
                                        <p className="text-base text-[#24245f]">
                                            {product.date_time
                                                ? new Date(
                                                      product.date_time
                                                  ).toLocaleTimeString(
                                                      currentLanguage,
                                                      {
                                                          hour: '2-digit',
                                                          minute: '2-digit',
                                                      }
                                                  )
                                                : ''}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex gap-4">
                                    <img
                                        width={30}
                                        height={30}
                                        alt="Location"
                                        src={location}
                                    />
                                    <div className="flex flex-col">
                                        <p className="text-sm font-normal text-[#24245f] opacity-80">
                                            {t('page.marketplace.location')}{' '}
                                        </p>
                                        <p className="text-base text-[#24245f]">
                                            {product.location
                                                ? product.location
                                                : ''}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {product && (
                            <div
                                className="rich-text-content"
                                dangerouslySetInnerHTML={{
                                    __html: product.content,
                                }}
                            ></div>
                        )}

                        {product &&
                            (product.type === ProductType.SEMINAR ||
                                product.type === ProductType.COACHING) && (
                                <div className="flex gap-4 border-t-2 pt-4">
                                    <img
                                        width={30}
                                        height={30}
                                        alt="Location"
                                        src={user}
                                    />
                                    <div className="flex flex-col">
                                        <p className="text-sm font-normal text-[#24245f] opacity-80">
                                            {t('page.marketplace.speakers')}{' '}
                                        </p>
                                        <div className="flex flex-col text-base text-[#24245f]">
                                            {product.trainer_coach &&
                                                product.trainer_coach.map(
                                                    (item: Coach) => {
                                                        return (
                                                            <p key={item.name}>
                                                                {item.name}
                                                            </p>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default PackageDetailsDialog;
