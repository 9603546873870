import { CircularProgress, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getColumns } from './MarketplaceWidgetColumns';
import { client } from 'src/utils/client';
import { GET_ALL_PRODUCTS } from 'src/const/apiUrls';
import { toast } from 'react-toastify';
import { Product } from 'src/pages/marketplace/Marketplace.interface';
import { LanguageContext } from 'src/context/LanguageContext';

type Props = {};

const MarketplaceWidget = (props: Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [productList, setProductList] = useState<any>([]);
    const language = useContext(LanguageContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let resp = await client.get(
                    GET_ALL_PRODUCTS(language.currentLanguage)
                );
                if (resp && resp.data && resp.data.data) {
                    let tempProducts: Array<Product> = resp.data.data.slice(
                        0,
                        5
                    );
                    setProductList(tempProducts);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error);
                toast.error('Something went wrong!');
            }
        };
        fetchData();
    }, [language.currentLanguage]);

    return (
        <div className="rounded-xl bg-[#fff] p-6">
            <h1 className="p-2 text-lg font-bold text-[#24245f99]">
                {t('common.marketplace')}
            </h1>
            <div
                style={{ width: '100%' }}
                className="d-flex w-full items-center justify-center"
            >
                {loading && (
                    <CircularProgress
                        style={{
                            position: 'relative',
                            top: '30vh',
                            left: '50%',
                            color: '#06D7A0',
                        }}
                    />
                )}
                {!loading && productList && productList.length > 0 && (
                    <DataGrid
                        style={{ overflowX: 'hidden' }}
                        sx={{
                            background: '#FFF',
                            borderBottomRightRadius: '20px',
                            borderBottomLeftRadius: '20px',
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            border: 'none',
                            overflowX: 'scroll',
                        }}
                        slots={{
                            loadingOverlay: LinearProgress,
                        }}
                        getRowId={(row: any) => row.id}
                        rows={productList}
                        columns={getColumns(language.currentLanguage)}
                        disableRowSelectionOnClick
                        disableColumnMenu
                        loading={loading}
                        getRowClassName={(params: any) => {
                            let textClass = 'text-sm text-[#1B1C19] border-0 ';
                            let backPrimaryClass = 'bg-[#FFFFFF] ';
                            let backSecondaryClass = 'bg-[#FAFAFA] ';
                            return params.indexRelativeToCurrentPage % 2 === 0
                                ? textClass + backPrimaryClass
                                : textClass + backSecondaryClass;
                        }}
                        pageSizeOptions={[5]}
                    />
                )}
                {productList && productList.length === 0 && !loading && (
                    <div className="my-auto flex h-full w-full items-center justify-center p-14 text-xl font-bold text-[#06D7A0]">
                        {t('common.comingSoon')}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MarketplaceWidget;
