import { useTranslation } from 'react-i18next';
import OnboardForm from './OnboardForm';

const Onboard = () => {
    const { t } = useTranslation();

    return (
        <div className="min-w-screen flex min-h-screen items-center justify-center bg-[#4ABFBF]">
            <div className="my-5 flex w-11/12 flex-col rounded-xl bg-[#FFF] p-8 lg:w-3/5">
                <div className="flex w-full flex-col items-center">
                    <h1 className="text-center text-3xl font-semibold leading-10 text-[#24245F]">
                        {t('auth.onboard.title')}
                    </h1>
                    <p className=" text-center text-base leading-7 text-[#24245F]">
                        {t('auth.onboard.subtitle')}
                    </p>
                </div>
                <OnboardForm isOnboard />
            </div>
        </div>
    );
};

export default Onboard;
