import PrimaryButton from 'src/components/buttons/PrimaryButton';
import { StepModel } from '../stepper/Stepper.interface';
import { useTranslation } from 'react-i18next';
import creditCard from 'src/assets/icons/money-check-duotone.svg';
import paypal from 'src/assets/icons/paypal.svg';
import invoice from 'src/assets/icons/FluentEmojiHighContrastReceipt.svg';
import { useContext, useState } from 'react';
import {
    PaymentType,
    CreateOrderModel,
    ConfirmDialogState,
    ConfirmDialogStatus,
    CardStatus,
} from '../Checkout.interface';
import CardPayment from './CardPayment';
import { CartContext } from 'src/context/CartContext';
import { Product } from 'src/pages/marketplace/Marketplace.interface';
import { client } from 'src/utils/client';
import { CREATE_ORDER, FINISH_ORDER } from 'src/const/apiUrls';
import {
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { CircularProgress, Dialog } from '@mui/material';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { toast } from 'react-toastify';
import {
    getOrder,
    removeAllProducts,
    removeOrder,
    saveOrder,
} from 'src/utils/cart/cartController';
import { useNavigate } from 'react-router-dom';

type Props = {
    steps: Array<StepModel>;
    activeStep: number;
    navigateTo: (step: number, currentStepValid?: boolean) => void;
    onStepChange: (step: number) => void;
    setCurrentStepValid: (valid: boolean) => void;
    totalAmount: number;
    setMainDialogOpen: (isOpen: boolean) => void;
};

const ConfirmationStep = ({
    setCurrentStepValid,
    steps,
    activeStep,
    navigateTo,
    onStepChange,
    totalAmount,
    setMainDialogOpen,
}: Props) => {
    const { t } = useTranslation();
    const elements = useElements();
    const stripe = useStripe();
    const { productList, setProductList } = useContext(CartContext);
    const navigate = useNavigate();

    const [cardStatus, setCardStatus] = useState<Array<CardStatus>>([
        {
            name: 'cardNumber',
            isComplete: false,
            errorMessage: '',
        },
        {
            name: 'cardExpiry',
            isComplete: false,
            errorMessage: '',
        },
        {
            name: 'cardCvc',
            isComplete: false,
            errorMessage: '',
        },
    ]);
    const [paymentType, setPaymentType] = useState<string>(
        PaymentType.CreditCard
    );
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [dialogState, setDialogState] = useState<ConfirmDialogState>({
        status: ConfirmDialogStatus.IDLE,
        message: '',
    });

    const handlePaymentTypeChange = (evt: any) => {
        setPaymentType(evt.target.value);
    };

    const onInvoiceSubmit = async () => {
        setIsDialogOpen(true);
        setDialogState({
            message: '',
            status: ConfirmDialogStatus.IN_PROGRESS,
        });

        let tempItems = productList.map((item: Product) => {
            return {
                product_id: item.id,
                quantity: 1,
            };
        });

        let payload = {
            payment_method: 'invoice',
            line_items: tempItems,
        };

        try {
            let orderRes = await client.post(CREATE_ORDER, payload);
            if (orderRes) {
                setDialogState({
                    message: t('checkout.orderSuccessfull'),
                    status: ConfirmDialogStatus.SUCCESS,
                });
                setProductList([]);
                removeAllProducts();
            }
        } catch (error: any) {
            console.log(error);

            setDialogState({
                message: error.message,
                status: ConfirmDialogStatus.ERROR,
            });
        }
    };

    const onStripeSubmit = async () => {
        let isValid = isCardValid();
        if (isValid) {
            setIsDialogOpen(true);
            setDialogState({
                message: '',
                status: ConfirmDialogStatus.IN_PROGRESS,
            });

            let tempItems = productList.map((item: Product) => {
                return {
                    product_id: item.id,
                    quantity: 1,
                };
            });

            let payload = {
                payment_method: 'stripe',
                line_items: tempItems,
            };

            try {
                //create order on server and collect payment intent
                let orderRes = await client.post(CREATE_ORDER, payload);
                if (orderRes && orderRes.data && orderRes.data.data) {
                    let createOrderModel: CreateOrderModel = orderRes.data.data;
                    if (elements && createOrderModel) {
                        if (stripe) {
                            let paymentMethod = {
                                payment_method: {
                                    card: elements.getElement(
                                        CardNumberElement
                                    )!,
                                    billing_details: {
                                        name:
                                            createOrderModel.billing_info &&
                                            createOrderModel.billing_info
                                                .first_name &&
                                            createOrderModel.billing_info
                                                .last_name
                                                ? createOrderModel.billing_info
                                                      .first_name +
                                                  ' ' +
                                                  createOrderModel.billing_info
                                                      .last_name
                                                : '',
                                        address: {
                                            line1:
                                                createOrderModel.billing_info &&
                                                createOrderModel.billing_info
                                                    .address_1
                                                    ? createOrderModel
                                                          .billing_info
                                                          .address_1
                                                    : '',
                                            line2:
                                                createOrderModel.billing_info &&
                                                createOrderModel.billing_info
                                                    .address_2
                                                    ? createOrderModel
                                                          .billing_info
                                                          .address_2
                                                    : '',
                                            city:
                                                createOrderModel.billing_info &&
                                                createOrderModel.billing_info
                                                    .city
                                                    ? createOrderModel
                                                          .billing_info.city
                                                    : '',
                                            country:
                                                createOrderModel.billing_info &&
                                                createOrderModel.billing_info
                                                    .country
                                                    ? createOrderModel
                                                          .billing_info.country
                                                    : '',
                                            postal_code:
                                                createOrderModel.billing_info &&
                                                createOrderModel.billing_info
                                                    .postcode
                                                    ? createOrderModel
                                                          .billing_info.postcode
                                                    : '',
                                            state:
                                                createOrderModel.billing_info &&
                                                createOrderModel.billing_info
                                                    .state
                                                    ? createOrderModel
                                                          .billing_info.state
                                                    : '',
                                        },
                                    },
                                },
                            };
                            try {
                                //finish payment on frontend
                                const result = await stripe.confirmCardPayment(
                                    createOrderModel.stripe_client_secret,
                                    paymentMethod
                                );
                                if (result) {
                                    let finishOrderPayload = {
                                        is_success: true,
                                        message: '',
                                    };

                                    if (result.error) {
                                        setDialogState({
                                            message: result.error.message
                                                ? result.error.message
                                                : '',
                                            status: ConfirmDialogStatus.ERROR,
                                        });
                                        finishOrderPayload.is_success = false;
                                        finishOrderPayload.message = result
                                            .error.message
                                            ? result.error.message
                                            : 'Unexpected error';
                                        console.log(result.error.message);
                                    } else {
                                        setDialogState({
                                            message: t(
                                                'checkout.orderSuccessfull'
                                            ),
                                            status: ConfirmDialogStatus.SUCCESS,
                                        });

                                        setProductList([]);
                                        removeAllProducts();
                                    }

                                    client.post(
                                        FINISH_ORDER(createOrderModel.order_id),
                                        finishOrderPayload
                                    );
                                }
                            } catch (error: any) {
                                console.log(error);
                                setDialogState({
                                    message: error.message ? error.message : '',
                                    status: ConfirmDialogStatus.ERROR,
                                });
                            }
                        }
                    }
                }
            } catch (error: any) {
                setDialogState({
                    message: error.message ? error.message : '',
                    status: ConfirmDialogStatus.ERROR,
                });
                console.log(error);
            }
        } else {
            toast.error(t('checkout.invalidCardInfo'));
        }
    };

    const cardOnChange = (event: any) => {
        let tempCardStatus = cardStatus.map((item: CardStatus) => {
            if (item.name === event.elementType) {
                item.isComplete = event.complete;
                item.errorMessage = event.error ? event.error.message : '';
            }
            return item;
        });
        setCardStatus(tempCardStatus);
    };

    const isCardValid = () => {
        return (
            cardStatus.filter((item: CardStatus) => !item.isComplete).length ===
            0
        );
    };

    const paypalCreateOrder = async (data: any, actions: any) => {
        let tempItems = productList.map((item: Product) => {
            return {
                product_id: item.id,
                quantity: 1,
            };
        });

        let payload = {
            payment_method: 'paypal',
            line_items: tempItems,
        };
        try {
            //create order on server and collect total amount
            let orderRes = await client.post(CREATE_ORDER, payload);
            if (orderRes && orderRes.data && orderRes.data.data) {
                let createOrderModel: CreateOrderModel = orderRes.data.data;
                saveOrder(createOrderModel.order_id);

                return actions.order.create({
                    ORDER_ID: createOrderModel.order_id,
                    purchase_units: [
                        {
                            amount: {
                                value: createOrderModel.total,
                            },
                        },
                    ],
                });
            }
        } catch (error: any) {
            setDialogState({
                message: error.message ? error.message : '',
                status: ConfirmDialogStatus.ERROR,
            });
            console.log(error);
        }
    };

    const paypalOnApprove = async (data: any, actions: any) => {
        let finishOrderPayload = {
            is_success: true,
            message: '',
        };

        setDialogState({
            message: t('checkout.orderSuccessfull'),
            status: ConfirmDialogStatus.SUCCESS,
        });
        setIsDialogOpen(true);
        setProductList([]);
        removeAllProducts();

        try {
            let tempOrderId = Number(getOrder());

            client.post(
                FINISH_ORDER(tempOrderId ? tempOrderId : -1),
                finishOrderPayload
            );
        } catch (error) {
            console.log(error);
            removeOrder();
        }
    };

    const paypalOnError = (error: any) => {
        let finishOrderPayload = {
            is_success: true,
            message: '',
        };
        setDialogState({
            message: error && error.message ? error.message : '',
            status: ConfirmDialogStatus.ERROR,
        });
        setIsDialogOpen(true);
        finishOrderPayload.is_success = false;
        finishOrderPayload.message =
            error && error.message ? error.message : 'Unexpected error';

        try {
            let tempOrderId = Number(getOrder());
            client.post(
                FINISH_ORDER(tempOrderId ? tempOrderId : -1),
                finishOrderPayload
            );
        } catch (error) {
            console.log(error);
            removeOrder();
        }
    };

    const paypalOnCancel = async () => {
        let finishOrderPayload = {
            is_success: false,
            message: 'Cancelled',
        };

        try {
            let tempOrderId = Number(getOrder());

            client.post(
                FINISH_ORDER(tempOrderId ? tempOrderId : -1),
                finishOrderPayload
            );
            removeOrder();
        } catch (error) {
            removeOrder();
            console.log(error);
        }
    };

    return (
        <div className="flex h-full flex-col gap-6">
            <h2 className="text-2xl font-semibold text-[#24245F]">
                {t('checkout.confirmation')}
            </h2>
            <div>
                <div className="flex flex-col gap-6 pt-6">
                    <h2 className="text-lg font-semibold text-[#24245F]">
                        {t('checkout.payWith')}
                    </h2>
                    <div className="flex flex-col justify-center gap-8 sm:flex-row sm:justify-start">
                        <div className="flex w-1/3 flex-col items-center gap-4 self-center rounded-3xl border-[#24245f14] bg-[#F8F8F8] px-6 py-8 sm:self-start">
                            <input
                                className="h-5 w-5"
                                name="paymentType"
                                value={PaymentType.CreditCard}
                                type="radio"
                                checked={paymentType === PaymentType.CreditCard}
                                onChange={handlePaymentTypeChange}
                            />
                            <img
                                src={creditCard}
                                className="h-10 w-11"
                                alt="card"
                            />
                            <p className="text-base font-semibold text-[#24245F]">
                                {t('checkout.creditOrDebitCard')}
                            </p>
                        </div>
                        <div className="flex w-1/3 flex-col items-center gap-4 self-center rounded-3xl border-[#24245f14] bg-[#F8F8F8] px-6 py-8 sm:self-start">
                            <input
                                className="h-5 w-5"
                                name="paymentType"
                                value={PaymentType.Paypal}
                                type="radio"
                                checked={paymentType === PaymentType.Paypal}
                                onChange={handlePaymentTypeChange}
                            />
                            <img
                                src={paypal}
                                className="h-10 w-12"
                                alt="paypal"
                            />
                            <p className="text-base font-semibold text-[#24245F]">
                                PayPal
                            </p>
                        </div>
                        <div className="flex w-1/3 flex-col items-center gap-4 self-center rounded-3xl border-[#24245f14] bg-[#F8F8F8] px-6 py-8 sm:self-start">
                            <input
                                className="h-5 w-5"
                                name="paymentType"
                                value={PaymentType.Invoice}
                                type="radio"
                                checked={paymentType === PaymentType.Invoice}
                                onChange={handlePaymentTypeChange}
                            />
                            <img
                                src={invoice}
                                className="h-10 w-12"
                                alt="paypal"
                            />
                            <p className="text-base font-semibold text-[#24245F]">
                                {t('checkout.invoice')}
                            </p>
                        </div>
                    </div>
                    {paymentType === PaymentType.CreditCard && (
                        <CardPayment onChange={cardOnChange} />
                    )}
                </div>
                <div className="mt-auto flex items-center justify-between pt-5">
                    {activeStep !== 1 && (
                        <button
                            className="mr-auto text-base font-semibold text-[#06D7A0] hover:text-[#06d79fb4]"
                            onClick={() => onStepChange(activeStep - 1)}
                        >
                            {t('common.back')}
                        </button>
                    )}
                    {activeStep === steps.length && (
                        <div>
                            <PayPalButtons
                                style={{
                                    layout: 'horizontal',
                                    color: 'blue',
                                    shape: 'rect',
                                    label: 'paypal',
                                    tagline: false,
                                    height: 55,
                                }}
                                className={
                                    paymentType === PaymentType.Paypal
                                        ? 'flex'
                                        : 'hidden'
                                }
                                createOrder={paypalCreateOrder}
                                onApprove={paypalOnApprove}
                                onError={paypalOnError}
                                onCancel={paypalOnCancel}
                            />
                            <PrimaryButton
                                onClick={onStripeSubmit}
                                className={
                                    paymentType === PaymentType.CreditCard
                                        ? 'flex'
                                        : 'hidden'
                                }
                                label={t('checkout.orderNow')}
                            />
                            <PrimaryButton
                                onClick={onInvoiceSubmit}
                                className={
                                    paymentType === PaymentType.Invoice
                                        ? 'flex'
                                        : 'hidden'
                                }
                                label={t('checkout.orderNow')}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Dialog open={isDialogOpen} fullWidth>
                <div className="flex w-full flex-col gap-6 px-6 py-10">
                    <div className="w-full">
                        <h3 className="text-xl font-semibold text-[#24245F] sm:text-base lg:text-2xl">
                            {t('checkout.orderProcess')}
                        </h3>
                    </div>
                    <div className="flex w-full flex-col gap-6">
                        {dialogState.status ===
                            ConfirmDialogStatus.IN_PROGRESS && (
                            <div className="flex w-full flex-col items-center justify-center gap-4">
                                <CircularProgress
                                    sx={{
                                        height: '130px',
                                    }}
                                />
                                {t('checkout.orderInProcess')}
                            </div>
                        )}
                        {dialogState.status !==
                            ConfirmDialogStatus.IN_PROGRESS && (
                            <div>
                                {dialogState.status ===
                                    ConfirmDialogStatus.ERROR && (
                                    <p className="text-base font-semibold text-red-500">
                                        {dialogState.message}
                                    </p>
                                )}
                                {dialogState.status ===
                                    ConfirmDialogStatus.SUCCESS && (
                                    <p className="text-base font-semibold text-[#06D7A0]">
                                        {dialogState.message}
                                    </p>
                                )}
                            </div>
                        )}

                        <PrimaryButton
                            label={t('common.confirm')}
                            disabled={
                                dialogState.status ===
                                    ConfirmDialogStatus.IN_PROGRESS ||
                                dialogState.status === ConfirmDialogStatus.IDLE
                            }
                            onClick={() => {
                                setMainDialogOpen(false);
                                setDialogState({
                                    message: '',
                                    status: ConfirmDialogStatus.IDLE,
                                });
                                navigate('/orders');
                            }}
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ConfirmationStep;
