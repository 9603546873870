import Step from './Step';
import { StepModel } from './Stepper.interface';

type Props = {
    steps: Array<StepModel>;
};

const CustomStepper = ({ steps }: Props) => {
    return (
        <div className="flex gap-2 sm:gap-6 lg:gap-10">
            {steps.map((step: StepModel) => {
                return <Step key={step.id} model={step}></Step>;
            })}
        </div>
    );
};

export default CustomStepper;
