import Header from 'src/components/layout/Header';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Profile from './tabs/Profile';
import BusinessDetails from './tabs/BusinessDetails';

const Settings = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const { t } = useTranslation();

    return (
        <div className="flex flex-col">
            <Header title={t('common.settings')} />
            <div className="mt-5 flex w-full flex-col gap-8 rounded-3xl bg-[#fff] px-6 py-4">
                <div className="flex border-b ">
                    <button
                        className={
                            activeIndex === 0
                                ? 'border-b-2 border-[#4ABFBF] px-6 py-4 text-xs font-semibold text-[#24245F] sm:text-base'
                                : ' px-6 py-4 text-xs sm:text-base'
                        }
                        onClick={() => setActiveIndex(0)}
                    >
                        {t('page.settings.profile')}
                    </button>
                    <button
                        className={
                            activeIndex === 1
                                ? 'border-b-2 border-[#4ABFBF] px-6 py-4 text-xs font-semibold text-[#24245F] sm:text-base'
                                : ' px-6 py-4 text-xs sm:text-base'
                        }
                        onClick={() => setActiveIndex(1)}
                    >
                        {t('page.settings.businessDetails')}
                    </button>
                </div>
                <div className="w-full">
                    {activeIndex === 0 && <Profile />}
                    {activeIndex === 1 && <BusinessDetails />}
                    {/* {activeIndex === 2 && <NotificationSettings />} */}
                </div>
            </div>
        </div>
    );
};

export default Settings;
