import { Link } from 'react-router-dom';
import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
    label: string;
    name: string;
    type: string;
    register: UseFormRegister<any>;
    placeholder?: string;
    showForgotPassword?: boolean;
    required?: boolean;
    disabled?: boolean | undefined;
    errors?: any;
    className?: string;
    onChange?: any;
    onFocus?: any;
};

const FormField = ({
    name,
    label,
    type,
    register,
    placeholder,
    showForgotPassword,
    required,
    errors,
    className,
    disabled,
    onChange,
    onFocus,
}: Props) => {
    let inputField;
    const { t } = useTranslation();

    switch (type) {
        case 'email':
            inputField = (
                <input
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: t('common.requiredField'),
                        },
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: t('common.invalidEmail'),
                        },
                    })}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                ></input>
            );
            break;
        case 'cardNumber':
            inputField = (
                <input
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: t('common.requiredField'),
                        },
                        pattern: {
                            value: /^(?:4[0-9]{12}(?:[0-9]{3})?| 5[1-5][0-9]{14}| 3[47][0-9]{13} | 6(?:011|5[0-9]{2})[0-9]{12} | 3(?:0[0-5]|[68][0-9])?[0-9]{11} | (?:2131|1800|35\d{3})\d{11})$/,
                            message: t('common.invalidCardNumber'),
                        },
                    })}
                    type={'number'}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={onChange ? onChange : () => {}}
                    onFocus={onFocus ? onFocus : () => {}}
                ></input>
            );
            break;
        case 'nameOnCard':
            inputField = (
                <input
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: t('common.requiredField'),
                        },
                        pattern: {
                            value: /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/,
                            message: t('common.invalidNameOnCard'),
                        },
                    })}
                    type={'text'}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={onChange ? onChange : () => {}}
                    onFocus={onFocus ? onFocus : () => {}}
                ></input>
            );
            break;
        case 'expiry':
            inputField = (
                <input
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: t('common.requiredField'),
                        },
                        pattern: {
                            value: /^(0[1-9]|1[0-2])\d{2}$/,
                            message: t('common.invalidExpiryDate'),
                        },
                    })}
                    type={'text'}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={onChange ? onChange : () => {}}
                    onFocus={onFocus ? onFocus : () => {}}
                ></input>
            );
            break;
        case 'cvc':
            inputField = (
                <input
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: t('common.requiredField'),
                        },
                        pattern: {
                            value: /^[0-9]{3,4}$/,
                            message: t('common.invalidCvc'),
                        },
                    })}
                    type={'number'}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={onChange ? onChange : () => {}}
                    onFocus={onFocus ? onFocus : () => {}}
                ></input>
            );
            break;
        case 'password':
            inputField = (
                <input
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: t('common.requiredField'),
                        },
                    })}
                    type={type}
                    disabled={disabled}
                    placeholder={placeholder}
                ></input>
            );
            break;
        case 'changePassword':
            inputField = (
                <input
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: t('common.requiredField'),
                        },
                        minLength: {
                            value: 10,
                            message: t('common.passwordMin10Char'),
                        },
                        maxLength: {
                            value: 100,
                            message: t('common.passwordMax100Char'),
                        },
                        pattern: {
                            value: /^(?=.*[A-Z])(?=.*[!@#?]).+$/,
                            message: t('common.passwordFormat'),
                        },
                    })}
                    type={'password'}
                    disabled={disabled}
                    placeholder={placeholder}
                ></input>
            );
            break;
        case 'text':
            inputField = (
                <input
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: t('common.requiredField'),
                        },
                    })}
                    type={type}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={onChange ? onChange : () => {}}
                    onFocus={onFocus ? onFocus : () => {}}
                ></input>
            );
            break;
        case 'textArea':
            inputField = (
                <textarea
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: t('common.requiredField'),
                        },
                    })}
                    rows={4}
                    disabled={disabled}
                    placeholder={placeholder}
                ></textarea>
            );
            break;
        default:
            inputField = <div></div>;
    }

    return (
        <div className={'flex flex-col gap-2 ' + className}>
            <div className="flex items-center justify-between">
                <label className="text-sm font-bold text-[#1B1C19]">
                    {label}
                    <span
                        className={
                            required
                                ? 'text-sm font-bold text-[#FF7E66]'
                                : 'hidden'
                        }
                    >
                        {' '}
                        *
                    </span>
                </label>
                {showForgotPassword && (
                    <Link
                        className="text-bold text-right text-sm font-bold text-[#06D7A0] hover:text-[#06d79f8e]"
                        to={'/forgot-password'}
                    >
                        Forgot your password?
                    </Link>
                )}
            </div>
            {inputField}
            {errors && (
                <span className="text-red-700">
                    {errors && errors[`${name}`] && errors[`${name}`].message}
                </span>
            )}
        </div>
    );
};

export default FormField;
