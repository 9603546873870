import { useTranslation } from 'react-i18next';
import { Product } from 'src/pages/marketplace/Marketplace.interface';

type Props = {
    products: Array<Product>;
    totalPrice: number;
    subTotalPrice: number;
    setSubTotalPrice: any;
    tax: number;
};

const Summary = ({
    products,
    tax,
    subTotalPrice,
    setSubTotalPrice,
    totalPrice,
}: Props) => {
    const { t } = useTranslation();

    return (
        <div>
            <h2 className="text-xl font-semibold text-[#24245F] sm:text-base lg:text-2xl">
                {t('checkout.summary')}
            </h2>
            <div className="flex flex-col gap-3 border-b-2 py-6">
                {products && products.length === 0 && (
                    <div>{t('common.noProductsFound')}</div>
                )}
                {products &&
                    products.map((item: Product, index: number) => {
                        return (
                            <div
                                key={item.id + '-' + index}
                                className="flex flex-col items-center justify-between gap-3"
                            >
                                <div className="flex w-full flex-1 flex-col justify-between lg:flex-row lg:items-center">
                                    <p className="break-all pr-6 text-xs font-medium text-[#24245F] xs:text-base">
                                        {item.title}
                                    </p>
                                    <p className="text-base text-[#24245F] xs:text-xl">
                                        {'€' + item.price.toFixed(2)}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div className="flex flex-col gap-4 border-b-2  py-6">
                <div className="flex flex-col justify-between lg:flex-row">
                    <p className="text-xs font-normal text-[#24245F] xs:text-base">
                        {t('checkout.subtotal')}
                    </p>
                    <p className="text-base font-normal text-[#24245F] xs:text-xl">
                        {'€' + subTotalPrice.toFixed(2)}
                    </p>
                </div>

                <div className="flex flex-col justify-between lg:flex-row">
                    <p className="text-xs font-normal text-[#24245F] xs:text-base">
                        {t('checkout.exclTax')}
                    </p>
                    <p className="text-base font-normal text-[#24245F] xs:text-xl">
                        {tax
                            ? '€' + (Math.floor(tax * 100) / 100).toFixed(2)
                            : ''}
                    </p>
                </div>
            </div>
            <div className="mt-6 flex flex-col">
                <div className="flex flex-col justify-between lg:flex-row">
                    <p className="text-xs font-semibold text-[#1B1C19] xs:text-base">
                        {t('checkout.totalAmountPrice')}
                    </p>
                    <p className="text-base font-bold text-[#4ABFBF] xs:text-2xl">
                        {'€' + totalPrice.toFixed(2)}
                    </p>
                </div>

                <p className="text-xs font-normal text-[#1B1C19] xs:text-sm lg:text-right">
                    {t('checkout.vatIncludedInTotals')}
                </p>
            </div>
        </div>
    );
};

export default Summary;
