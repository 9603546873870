import { Link } from "react-router-dom";

type Props = {
    label: string;
    onClick?: () => void;
    className?: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
    buttonRef?: any;
    href: string;
};

const ButtonLink = ({ label, className, buttonRef, href }: Props) => {
    return (
        <Link
            className={
                'rounded-2xl bg-[#06D7A0] px-8 py-3 text-center text-white hover:bg-[#06d79fb4] ' +
                className
            }
            to={href ? href : '/'}
        >
            {label}
        </Link>
    );
};

export default ButtonLink;
