import bannerImg from 'src/assets/images/telecommuting-pana.svg';
import diamond from 'src/assets/images/diamond.svg';
import gift from 'src/assets/images/gift.svg';
import { Avatar } from '@mui/material';
import { stringAvatar } from 'src/utils/avatarUtils';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'src/context/UserContext';
import { useContext, useEffect, useState } from 'react';
import { GET_USER_SUMMARY } from 'src/const/apiUrls';
import { client } from 'src/utils/client';

type Props = {};

export interface UserSummary {
    totalSpent: number;
    totalOrders: number;
}

const WelcomeWidget = (props: Props) => {
    const { t } = useTranslation();
    const { appUser } = useContext(UserContext);
    const [userSummary, setUserSummary] = useState<UserSummary>({
        totalOrders: 0,
        totalSpent: 0,
    });

    useEffect(() => {
        let fetchData = async () => {
            try {
                let resp = await client.get(GET_USER_SUMMARY);
                if (resp && resp.data && resp.data.data) {
                    let tempSummary: UserSummary = {
                        totalOrders: resp.data.data.count
                            ? resp.data.data.count
                            : 0,
                        totalSpent: resp.data.data.total
                            ? resp.data.data.total
                            : 0,
                    };
                    setUserSummary(tempSummary);
                }
            } catch (error) {}
        };
        fetchData();
    }, []);
    return (
        <div className="flex flex-col rounded-xl bg-[#4abfbf24] lg:flex-row">
            <div className="flex w-full flex-col gap-11 p-6 lg:w-2/3 ">
                <div className="flex items-center gap-3">
                    {appUser && appUser.name && (
                        <Avatar
                            {...stringAvatar(appUser.name ? appUser.name : ' ')}
                        />
                    )}
                    <h3 className="text-2xl font-bold leading-7 text-[#24245F]">
                        {t('page.home.welcomeBack')}, {appUser && appUser.name}
                    </h3>
                </div>
                <div className="flex flex-col items-center gap-7 sm:flex-row">
                    <div className="flex gap-3 sm:items-center">
                        <img src={diamond} alt="diamond" />
                        <div className="flex flex-col">
                            <p className="text-2xl font-semibold text-[#24245F] opacity-70">
                                {userSummary
                                    ? '€ ' + userSummary.totalSpent.toFixed(2)
                                    : '-'}
                            </p>
                            <p className="text-sm font-normal text-[#24245F]">
                                {t('page.home.spentTotal')}
                            </p>
                        </div>
                    </div>
                    <div className="flex gap-3 sm:items-center">
                        <img src={gift} alt="gift" />
                        <div className="flex flex-col">
                            <p className="text-2xl font-semibold text-[#24245F]  opacity-70">
                                {userSummary ? userSummary.totalOrders : '-'}
                            </p>
                            <p className="text-sm font-normal text-[#24245F]">
                                {t('page.home.totalOrders')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <img
                src={bannerImg}
                alt="banner"
                className="h-full max-h-[200px] w-full lg:w-1/3"
            />
        </div>
    );
};

export default WelcomeWidget;
