import { useEffect, useState } from 'react';
import { GET_USER } from 'src/const/apiUrls';
import {
    IndividualData,
    RegisteredCompanyData,
} from 'src/pages/authentication/Authentication.interface';
import OnboardForm from 'src/pages/authentication/onboard/OnboardForm';
import { client } from 'src/utils/client';

const BusinessDetails = () => {
    const [defaultValuesIndividual, setDefaultValuesIndividual] =
        useState<IndividualData>();
    const [defaultValuesRegistered, setDefaultValuesRegistered] =
        useState<RegisteredCompanyData>();

    let fetchUserData = async () => {
        try {
            let resp = await client.get(GET_USER + '?asdasds');

            if (resp && resp.data && resp.data.data) {
                if (resp.data.data.is_registered_company) {
                    let temp: RegisteredCompanyData = resp.data.data;
                    setDefaultValuesRegistered(temp);
                } else {
                    let temp: IndividualData = resp.data.data;
                    setDefaultValuesIndividual(temp);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    return (
        <div className="flex flex-col">
            <OnboardForm
                fetchUserData={fetchUserData}
                isOnboard={false}
                defaultValuesIndividual={defaultValuesIndividual}
                defaultValuesRegistered={defaultValuesRegistered}
            />
        </div>
    );
};

export default BusinessDetails;
