import ChangePassword from '../components/ChangePassword';

type Props = {};

const Profile = (props: Props) => {
    return (
        <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-5 lg:flex-row">
                {/* <div className="flex  w-full flex-1 lg:w-1/2">
                    <ImageUpload />
                </div> */}
                <div className="flex  w-full flex-1 lg:w-1/2">
                    <ChangePassword />
                </div>
            </div>
        </div>
    );
};

export default Profile;
