import { useForm } from 'react-hook-form';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import FormField from 'src/components/formField/FormField';
import { RecoverPasswordData } from './Authentication.interface';
import logo from 'src/assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import { REQUEST_RESET_PASSWORD } from 'src/const/apiUrls';
import authConfirm from 'src/assets/images/auth-confirm.svg';

const ForgotPassword = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RecoverPasswordData>();
    const navigate = useNavigate();

    const onSubmit = async (data: RecoverPasswordData) => {
        try {
            let resp = await axios.post(REQUEST_RESET_PASSWORD, {
                email: data.email,
            });

            if (resp && resp.status === 200) {
                navigate('/confirm-reset');
            }
        } catch (error: any) {
            console.log(error);
            toast.error(error.message);
        }
    };
    const { t } = useTranslation();

    return (
        <div className="min-w-screen flex min-h-screen items-center justify-center bg-[#4ABFBF]">
            <div className="my-5 flex w-11/12 flex-col rounded-xl bg-[#FFF] sm:my-0 sm:flex-row lg:w-3/5">
                <div className="flex w-full flex-col p-8 sm:w-1/2">
                    <h1 className="text-2xl font-semibold leading-10 text-[#24245F]">
                        {t('auth.recoverPassword.resetPassword')}
                    </h1>
                    <p className="pb-10 text-base leading-7 text-[#24245F]">
                        {t('auth.recoverPassword.resetPasswordSubtitle')}
                    </p>

                    <div className="w-full">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="flex w-full flex-col gap-5"
                        >
                            <FormField
                                name="email"
                                register={register}
                                type="email"
                                label={t('auth.login.email')}
                                placeholder={t('auth.login.enterYourEmail')}
                                required
                                errors={errors}
                                className="w-full"
                            />

                            <PrimaryButton
                                className="w-fit"
                                label={t('common.send')}
                            />
                        </form>
                    </div>
                </div>
                <div className="flex w-full flex-col items-center justify-between gap-5 p-8 sm:w-1/2">
                    <img src={logo} alt="logo" width={170} height={50} />
                    <div className="flex flex-col gap-6">
                        <img
                            src={authConfirm}
                            alt="logo"
                            width={200}
                            height={70}
                        />
                    </div>
                    <div
                        className={
                            'flex flex-col justify-center xl:flex-row xl:justify-around'
                        }
                    >
                        <a
                            className="px-8 py-3 text-center text-[#06D7A0] hover:text-[#06d79fb4]"
                            href={'https://digitale-gruendung.de/datenschutz/'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('common.privacyPolicy')}
                        </a>
                        <a
                            className="px-8 py-3 text-center text-[#06D7A0] hover:text-[#06d79fb4]"
                            href={'https://digitale-gruendung.de/agb/'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('common.termsConditions')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
