import Header from 'src/components/layout/Header';
import { useContext, useEffect, useState } from 'react';
import { client } from 'src/utils/client';
import { GET_ALL_CATEGORIES, GET_ALL_PRODUCTS } from 'src/const/apiUrls';
import {
    Filter,
    Product,
    ProductCategory,
    ProductTab,
    ProductType,
} from './Marketplace.interface';
import { CustomSlider } from './components/CustomSlider';
import PackageCard from 'src/components/packageCard/PackageCard';
import PackageDetailsDialog from 'src/components/dialogs/PackageDetailsDialog';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from 'src/context/LanguageContext';

const Marketplace = () => {
    const tabList: Array<ProductTab> = [
        {
            name: 'type',
            type: '',
            label: 'all',
        },
        {
            name: 'type',
            type: ProductType.PACKAGE,
            label: 'package',
        },
        {
            name: 'type',
            type: ProductType.PRODUCT,
            label: 'product',
        },
        {
            name: 'type',
            type: ProductType.COACHING,
            label: 'coaching',
        },
        {
            name: 'type',
            type: ProductType.SEMINAR,
            label: 'seminar',
        },
    ];
    const [priceRange, setPriceRange] = useState<number[]>([0, 10000]);
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [maxPrice, setMaxPrice] = useState<number>(10000);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const { t } = useTranslation();
    const language = useContext(LanguageContext);

    const [productList, setProductList] = useState<Array<Product>>([]);
    const [filteredProductList, setFilteredProductList] = useState<
        Array<Product>
    >([]);
    const [productCategories, setProductCategories] = useState<
        Array<ProductCategory>
    >([]);

    const [filters, setFilters] = useState<Filter>({
        type: '',
        category: [],
        minPrice: undefined,
        maxPrice: undefined,
        productTitle: '',
    });

    const priceRangeHandleChange = (
        event: any,
        newValue: number | number[]
    ) => {
        let priceArray: Array<number> = newValue as number[];
        setPriceRange(priceArray);
        filterOnChangeHandler(null, undefined, priceArray[0], priceArray[1]);
    };

    const filterOnChangeHandler = (
        event: any,
        category?: ProductCategory,
        minPrice?: number,
        maxPrice?: number
    ) => {
        if (event && !category) {
            let tempFilter: Filter = {
                ...filters,
                [`${event.target.name}`]: event.target.value,
            };

            setFilters(tempFilter);
            return;
        }

        if (category) {
            let categoryFilterArray: Array<ProductCategory> = filters[
                'category'
            ]
                ? filters['category']
                : [];

            let tempCategoryFilter: Filter = {
                ...filters,
            };

            if (
                categoryFilterArray.find(
                    (item: ProductCategory) => item.id === category.id
                )
            ) {
                tempCategoryFilter.category = categoryFilterArray.filter(
                    (item: ProductCategory) => item.id !== category.id
                );
            } else {
                tempCategoryFilter.category = [
                    ...categoryFilterArray,
                    category,
                ];
            }

            setFilters(tempCategoryFilter);
            return;
        }

        if (
            minPrice !== null &&
            minPrice !== undefined &&
            maxPrice !== null &&
            maxPrice !== undefined
        ) {
            let tempPriceFilter: Filter = {
                ...filters,
                minPrice: minPrice,
                maxPrice: maxPrice,
            };
            setFilters(tempPriceFilter);
            return;
        }
    };

    useEffect(() => {
        const getAllProducts = async () => {
            let resp = await client.get(
                GET_ALL_PRODUCTS(language.currentLanguage)
            );
            let tempProductList: Array<Product> = resp.data.data;
            let maxPriceProduct = tempProductList[0];
            tempProductList.forEach((item: Product) => {
                if (Number(maxPriceProduct.price) < Number(item.price)) {
                    maxPriceProduct = item;
                }
            });
            console.log(resp);
            setMaxPrice(Number(maxPriceProduct.price));
            setProductList(tempProductList);
            setFilteredProductList(tempProductList);
        };
        const getAllCategories = async () => {
            let resp = await client.get(
                GET_ALL_CATEGORIES(language.currentLanguage)
            );
            let tempCategories: Array<ProductCategory> = resp.data.data;
            setProductCategories(tempCategories);
        };
        getAllProducts();
        getAllCategories();
    }, [language.currentLanguage]);

    useEffect(() => {
        let tempFilteredProducts: Array<Product> = [];
        if (filters) {
            tempFilteredProducts = productList.filter((item: Product) => {
                return (
                    (filters['category'] && filters['category'].length > 0
                        ? filters['category']
                              .map((cat: ProductCategory) => cat.id)
                              .includes(item.category[0].id)
                        : true) &&
                    (filters['type'] ? item.type === filters['type'] : true) &&
                    (filters['minPrice']
                        ? item.price >= filters['minPrice']
                        : true) &&
                    (filters['productTitle']
                        ? item.title
                              .toLowerCase()
                              .includes(filters['productTitle'].toLowerCase())
                        : true) &&
                    (filters['maxPrice']
                        ? item.price <= filters['maxPrice']
                        : true)
                );
            });

            setFilteredProductList(tempFilteredProducts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, productList]);

    return (
        <div className="flex flex-col gap-8">
            <Header title={t('common.marketplace')} />
            <div className="flex flex-col gap-8 xl:flex-row">
                {/* ******************** Filters ******************** */}
                <div className="flex h-fit w-full flex-col gap-7 rounded-2xl bg-[#fff] px-3 py-6 xl:w-2/6">
                    <p className="color-[#24245F] text-xs">
                        {filteredProductList && filteredProductList.length}{' '}
                        {t('page.marketplace.items')}
                    </p>
                    <div className="flex flex-col gap-3 rounded-[13px] border border-[#d1d1d166] px-6 py-3">
                        <p className="text-lg text-[#303D69]">
                            {t('page.marketplace.searchByProductTitle')}
                        </p>
                        <input
                            className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                            type="text"
                            name="productTitle"
                            value={filters['productTitle']}
                            onChange={(event) => filterOnChangeHandler(event)}
                        />
                    </div>
                    <div className="flex flex-col gap-12 rounded-[13px] border border-[#d1d1d166] px-6 py-3">
                        <p className="text-lg text-[#303D69]">
                            {t('page.marketplace.priceRange')}
                        </p>
                        <div className="flex items-center gap-3">
                            <span className="pr-2 text-[#24245f80]">€0</span>
                            <CustomSlider
                                value={priceRange}
                                onChangeCommitted={priceRangeHandleChange}
                                min={0}
                                max={maxPrice}
                                valueLabelDisplay="on"
                                step={100}
                            />
                            <span className="flex  w-fit pl-2 text-[#24245f80]">
                                €{maxPrice}+
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-6 rounded-[13px] border border-[#d1d1d166] px-6 py-3">
                        <p className="text-lg text-[#303D69]">
                            {t('page.marketplace.category')}
                        </p>
                        <div className="flex flex-col gap-3">
                            {productCategories &&
                                productCategories.map(
                                    (item: ProductCategory) => {
                                        return (
                                            <div
                                                key={item.id}
                                                className="flex items-center gap-2"
                                            >
                                                <input
                                                    className="h-5 w-5"
                                                    type="checkbox"
                                                    name="category"
                                                    value={item.id}
                                                    onChange={(event) =>
                                                        filterOnChangeHandler(
                                                            event,
                                                            item
                                                        )
                                                    }
                                                />
                                                <label className="text-sm text-[#24245F]">
                                                    {item.name}
                                                </label>
                                            </div>
                                        );
                                    }
                                )}
                        </div>
                    </div>
                </div>
                {/* ******************** Data ******************** */}
                <div className="flex w-full flex-col rounded-2xl bg-[#fff] px-3 py-6 xl:w-4/6">
                    <div className="flex flex-col">
                        <div className="flex border-b ">
                            {tabList.map((item: ProductTab) => {
                                return (
                                    <button
                                        key={item.label}
                                        name={item.name}
                                        className={
                                            filters['type'] === item.type
                                                ? 'border-b-2 border-[#4ABFBF] px-6 py-4 text-xs font-semibold text-[#24245F] sm:text-base'
                                                : ' px-6 py-4 text-xs sm:text-base'
                                        }
                                        value={item.type}
                                        onClick={filterOnChangeHandler}
                                    >
                                        {t(`page.marketplace.${item.label}`)}
                                    </button>
                                );
                            })}
                        </div>
                        <div>
                            <div className="flex flex-wrap">
                                {filteredProductList &&
                                    filteredProductList.length > 0 &&
                                    filteredProductList.map((item: Product) => {
                                        return (
                                            <div
                                                key={item.id}
                                                className="w-full p-2 sm:w-1/2 xl:w-1/3"
                                            >
                                                <PackageCard
                                                    product={item}
                                                    moreButtonOnClick={() => {
                                                        setDialogOpen(true);
                                                        setSelectedProduct(
                                                            item
                                                        );
                                                    }}
                                                    showBuyButton
                                                />
                                            </div>
                                        );
                                    })}
                                {filteredProductList &&
                                    filteredProductList.length === 0 && (
                                        <div className="my-auto flex h-full w-full items-center justify-center p-14 text-xl font-bold text-[#4ABFBF]">
                                            {t('common.comingSoon')}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PackageDetailsDialog
                product={selectedProduct}
                dialogOpen={dialogOpen}
                onClose={(event, reason) => {
                    setDialogOpen(false);
                }}
                setDialogOpen={setDialogOpen}
            />
        </div>
    );
};

export default Marketplace;
