import { useNavigate } from 'react-router-dom';
import SecondaryButton from 'src/components/buttons/SecondaryButton';

type Props = {};

const NotFound = (props: Props) => {
    const navigate = useNavigate();
    return (
        <div className="flex h-screen w-screen flex-col items-center justify-center gap-10">
            <span className="text-2xl font-bold ">Page is not found</span>
            <SecondaryButton
                label="Go back home"
                type="button"
                onClick={() => navigate('/')}
            />
        </div>
    );
};

export default NotFound;
