import check from 'src/assets/icons/check-white.svg';
import { StepModel } from './Stepper.interface';
import { useTranslation } from 'react-i18next';

type Props = {
    model: StepModel;
};

const Step = ({ model }: Props) => {
    const { t } = useTranslation();
    let activeClassName =
        model.isActive && !model.isFinished
            ? 'bg-[#4ABFBF] border-0 text-white'
            : 'border bg-transparent border-[#24245F]';

    let finishedComponent = <img className="h-5 w-5" src={check} alt="Check" />;

    return (
        <div className="flex w-fit gap-2">
            {model.isFinished && (
                <div
                    className={
                        'flex h-10 w-10 items-center justify-center rounded-full border-0 bg-[#4ABFBF] font-semibold '
                    }
                >
                    {model.isFinished && finishedComponent}
                </div>
            )}

            {!model.isFinished && (
                <div
                    className={
                        'flex h-10 w-10 items-center justify-center rounded-full font-bold ' +
                        activeClassName
                    }
                >
                    {!model.isFinished && model.id}
                </div>
            )}
            <div className="flex w-fit flex-col justify-center">
                <p className="hidden text-sm font-semibold text-[#1B1C19] sm:flex">
                    {t(`${model.title}`)}
                </p>
                {/* <p className="hidden text-xs text-[#708191] md:flex lg:hidden xl:flex">
                    {model.subtitle}
                </p> */}
            </div>
        </div>
    );
};

export default Step;
