import Header from 'src/components/layout/Header';
import WelcomeWidget from './components/WelcomeWidget';
import LatestOrders from './components/LatestOrders';
import { useTranslation } from 'react-i18next';
import MarketplaceWidget from './components/MarketplaceWidget';
import UpcomingSeminars from './components/UpcomingSeminars';
import MostBookedCoachings from './components/MostBookedCoachings';

const Home = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-8">
            <Header title={t('common.dashboard')} />
            <div className="flex flex-col gap-7 xl:flex-row">
                <div className="flex w-full flex-col gap-4 xl:w-3/5">
                    <WelcomeWidget />
                    <MostBookedCoachings />
                    <MarketplaceWidget />
                </div>
                <div className="flex w-full flex-col gap-4 xl:w-2/5">
                    <UpcomingSeminars />
                    <LatestOrders />
                </div>
            </div>
        </div>
    );
};

export default Home;
