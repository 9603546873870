import cross from 'src/assets/icons/cross-grey.svg';
import PrimaryButton from '../buttons/PrimaryButton';
import { useEffect, useState } from 'react';
import CheckoutDialog from '../dialogs/checkoutDialog/CheckoutDialog';
import { useTranslation } from 'react-i18next';
import { Product } from 'src/pages/marketplace/Marketplace.interface';
import { removeFromCart } from 'src/utils/cart/cartController';
import { toast } from 'react-toastify';

type Props = {
    productList: Array<Product>;
    setProductList: (productList: Array<Product>) => void;
};

const CartPopover = ({ productList, setProductList }: Props) => {
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {
        let tempTotal: number = 0;
        if (productList) {
            productList.map((item: Product) => (tempTotal += item.price));
        }
        setTotalPrice(Number(tempTotal));
    }, [productList]);

    const removeProduct = (productId?: number) => {
        if (productId) {
            let tempProductList: Array<Product> = productList.filter(
                (item: Product) => item.id !== productId
            );

            removeFromCart(productId);
            setProductList(tempProductList);
        }
    };

    return (
        <div className="flex flex-col p-6">
            <div className="flex flex-col gap-3">
                {productList && productList.length === 0 && (
                    <div>{t('common.noProductsFound')}</div>
                )}
                {productList &&
                    productList.map((item: Product) => {
                        return (
                            <div
                                key={item.id}
                                className="flex items-center justify-between gap-3"
                            >
                                <div className="flex flex-1 items-center justify-between">
                                    <p className="break-all pr-6 text-base text-[#1B1C19]">
                                        {item.title ? item.title : ''}
                                    </p>
                                    <p className="text-xl font-semibold text-[#1B1C19]">
                                        {item.price && item.price !== 0
                                            ? '€' + item.price.toFixed(2)
                                            : ''}
                                    </p>
                                </div>
                                <button
                                    onClick={() => removeProduct(item.id)}
                                    className="border-0 bg-transparent"
                                >
                                    <img
                                        src={cross}
                                        alt="cross"
                                        className="h-6 w-6"
                                    />
                                </button>
                            </div>
                        );
                    })}
            </div>
            <div className="mt-6 flex flex-col border-t-2 pt-5">
                <div className="flex flex-col">
                    <div className="flex items-center justify-between">
                        <p className="text-base font-bold text-[#1B1C19]">
                            {t('checkout.subtotal')}
                        </p>
                        <p className=" text-xl font-semibold text-[#1B1C19]">
                            {totalPrice ? '€' + totalPrice.toFixed(2) : ''}
                        </p>
                    </div>
                    <p className="text-sm font-normal text-[#24245F]">
                        {t('checkout.vatCalculatedAtCheckout')}
                    </p>
                    {/* <div className="mb-8 mt-4 flex items-center gap-2 rounded-2xl bg-[#a3ed89] bg-opacity-25 px-2 py-5">
                        <img src={check} className="h-5 w-5" alt="Check" />
                        <p className="text-base font-normal text-[#00BB93]">
                            Content creation of the link is{' '}
                            <span className="font-bold">included</span> in the
                            price
                        </p>
                    </div> */}
                    <div className="mt-4 flex justify-end">
                        <PrimaryButton
                            type="button"
                            label={t('checkout.goToCheckout')}
                            className="rounded-2xl font-bold"
                            onClick={() => {
                                if (productList.length > 0) {
                                    setDialogOpen(true);
                                } else {
                                    toast.warn(t('common.noProductsFound'));
                                }
                            }}
                        ></PrimaryButton>
                    </div>
                </div>
            </div>
            <CheckoutDialog
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        setDialogOpen(false);
                    }
                }}
            />
        </div>
    );
};

export default CartPopover;
