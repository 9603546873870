import authConfirm from 'src/assets/images/auth-confirm.svg';
import logo from 'src/assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

const ConfirmReset = () => {
    const { t } = useTranslation();
    return (
        <div className="min-w-screen flex min-h-screen items-center justify-center bg-[#4ABFBF]">
            <div className="my-5 flex w-11/12 flex-col items-center gap-8 rounded-xl bg-[#FFF] p-8 lg:w-3/5">
                <img src={logo} alt="logo" width={170} height={50} />
                <div className="flex flex-col items-center">
                    <h1 className="text-2xl font-semibold leading-10 text-[#24245F]">
                        {t('auth.resetConfirmation.emailSentTitle')}
                    </h1>
                    <p className="text-base leading-7 text-[#24245F]">
                        {t('auth.resetConfirmation.emailSentSubtitle')}
                    </p>
                </div>
                <img src={authConfirm} alt="logo" width={200} height={80} />
            </div>
        </div>
    );
};

export default ConfirmReset;
