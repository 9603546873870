import grid from 'src/assets/icons/rectangles-mixed-solid.svg';
import market from 'src/assets/icons/store-solid.svg';
import orders from 'src/assets/icons/square-list-solid.svg';
import settings from 'src/assets/icons/gear-solid.svg';

export interface RouteUrl {
    url: string;
    name: string;
    label: string;
    icon?: string;
    showInMenu?: boolean;
    active?: boolean;
}

export const protectedRoutesUrls: Array<RouteUrl> = [
    {
        url: '/',
        name: 'dashboard',
        label: 'common.dashboard',
        icon: grid,
        showInMenu: true,
    },
    {
        url: '/marketplace',
        name: 'marketplace',
        label: 'common.marketplace',
        icon: market,
        showInMenu: true,
    },
    {
        url: '/orders',
        name: 'orders',
        label: 'common.orders',
        icon: orders,
        showInMenu: true,
    },
    {
        url: '/settings',
        name: 'settings',
        label: 'common.settings',
        icon: settings,
        showInMenu: true,
    },
];

export const publicRoutesUrls: Array<RouteUrl> = [
    {
        url: '/sign-in',
        name: 'signIn',
        label: 'Sign In',
    },
    {
        url: '/sign-up',
        name: 'signUp',
        label: 'Sign Up',
    },
    {
        url: '/forgot-password',
        name: 'forgotPassword',
        label: 'Forgot Password',
    },
    {
        url: '/confirm-email',
        name: 'confirmEmail',
        label: 'Confirm Email',
    },
];
