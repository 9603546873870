export interface FiltersMarketplace {
    title: string;
}
export interface ProductCategory {
    id: number;
    name: string;
}
export interface Product {
    id: number;
    title: string;
    type: ProductType;
    price: number;
    short_description: string;
    category: Array<ProductCategory>;
    image: any;
    trainer_coach?: Array<Coach>;
    date_time?: string;
    features: Array<ProductFeature>;
    location: string;
    content: string;
}

export interface ProductFeature {
    ID: number;
    post_title: string;
    post_content: string;
}
export interface Coach {
    name: string;
    profession: string;
    image: any;
    bio: string;
    social: Social;
}

export interface Social {
    linkedin: string;
    email: string;
}

export enum ProductType {
    PRODUCT = 'product',
    PACKAGE = 'package',
    COACHING = 'coaching',
    SEMINAR = 'seminar',
}

export interface Filter {
    type?: string;
    category?: Array<ProductCategory>;
    minPrice?: number;
    maxPrice?: number;
    productTitle?: string;
}

export interface ProductTab {
    name: string;
    type: ProductType | string;
    label: string;
}
