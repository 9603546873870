import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import FormField from 'src/components/formField/FormField';
import SelectField from 'src/components/formField/SelectField';
import { SelectFieldData } from 'src/components/formField/SelectField.interface';
import { GET_USER, ONBOARD } from 'src/const/apiUrls';
import { client } from 'src/utils/client';
import OnboardSuccessDialog from './OnboardSuccessDialog';
import { setIsValidUser } from 'src/utils/auth';
import { RegisteredCompanyData } from '../Authentication.interface';

type Props = {
    countries: Array<SelectFieldData>;
    registeredCompanyData?: RegisteredCompanyData;
    isOnboardProcess?: boolean;
    fetchUserData?: () => void;
};

const RegisteredCompanyForm = ({
    countries,
    registeredCompanyData,
    isOnboardProcess,
    fetchUserData,
}: Props) => {
    const selectFieldData: Array<SelectFieldData> = [
        {
            value: 'Einzelunternehmen',
            label: 'Einzelunternehmen',
        },
        {
            value: 'Gmbh',
            label: 'Gmbh',
        },
        {
            value: 'GbR',
            label: 'GbR',
        },
        {
            value: 'Holding',
            label: 'Holding',
        },
        {
            value: 'gGmbH/gUG',
            label: 'gGmbH/gUG',
        },
        {
            value: 'GmbHCo.KG',
            label: 'GmbHCo.KG',
        },
        {
            value: 'AG',
            label: 'AG',
        },
    ];

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<RegisteredCompanyData>();

    useEffect(() => {
        if (registeredCompanyData) {
            setValue(
                'company_name',
                registeredCompanyData.company_name
                    ? registeredCompanyData.company_name
                    : ''
            );
            setValue(
                'legal_business_name',
                registeredCompanyData.legal_business_name
                    ? registeredCompanyData.legal_business_name
                    : ''
            );
            setValue(
                'legal_entity_id',
                registeredCompanyData.legal_entity_id
                    ? registeredCompanyData.legal_entity_id
                    : ''
            );
            setValue(
                'address',
                registeredCompanyData.address
                    ? registeredCompanyData.address
                    : ''
            );
            setValue(
                'address_2',
                registeredCompanyData.address_2
                    ? registeredCompanyData.address_2
                    : ''
            );
            setValue(
                'city',
                registeredCompanyData.city ? registeredCompanyData.city : ''
            );
            setValue(
                'phone',
                registeredCompanyData.phone ? registeredCompanyData.phone : ''
            );
            setValue(
                'state',
                registeredCompanyData.state ? registeredCompanyData.state : ''
            );
            setValue(
                'website',
                registeredCompanyData.website
                    ? registeredCompanyData.website
                    : ''
            );
            setValue(
                'zip_code',
                registeredCompanyData.zip_code
                    ? registeredCompanyData.zip_code
                    : 0
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registeredCompanyData]);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const handleOnboardSubmit = async (onboardData: RegisteredCompanyData) => {
        onboardData.is_registered_company = true;

        if (!isOnboardProcess) {
            try {
                let resp = await client.post(GET_USER, onboardData);
                if (resp && resp.data && resp.data.success) {
                    toast.success('Successfully onboarded.');
                    if (fetchUserData) {
                        fetchUserData();
                    }
                    return;
                }
            } catch (error: any) {
                console.log(error);
                toast.error(error.message);
                return;
            }
        }
        try {
            let resp = await client.post(ONBOARD, onboardData);
            if (resp && resp.data && resp.data.success) {
                setIsDialogOpen(true);
                setIsValidUser(true);
            }
        } catch (error: any) {
            console.log(error);
            toast.error(error.message);
        }
    };

    return (
        <div>
            <form
                onSubmit={handleSubmit(handleOnboardSubmit)}
                className="flex w-full flex-col gap-5"
            >
                <FormField
                    name="company_name"
                    register={register}
                    type="text"
                    label={t('auth.onboard.companyName')}
                    placeholder={t('auth.onboard.companyNamePlaceHolder')}
                    required
                    errors={errors}
                    className="w-full"
                />
                <SelectField
                    title={t('auth.onboard.typeOfBusiness')}
                    name="legal_entity_id"
                    required
                    register={register}
                    items={selectFieldData}
                    errors={errors}
                />
                <FormField
                    name="legal_business_name"
                    register={register}
                    type="text"
                    label={t('auth.onboard.legalName')}
                    placeholder={t('auth.onboard.legalNamePlaceholder')}
                    required
                    errors={errors}
                    className="w-full"
                />
                <FormField
                    name="tax_id"
                    register={register}
                    type="text"
                    label={t('auth.onboard.taxId')}
                    placeholder={t('auth.onboard.taxIdPlaceholder')}
                    errors={errors}
                    className="w-full"
                />
                <FormField
                    name="phone"
                    register={register}
                    type="text"
                    label={t('auth.onboard.phone')}
                    placeholder={t('auth.onboard.phonePlaceholder')}
                    required
                    errors={errors}
                    className="w-full"
                />
                <FormField
                    name="website"
                    register={register}
                    type="text"
                    label={t('auth.onboard.website')}
                    placeholder={t('auth.onboard.websitePlaceholder')}
                    errors={errors}
                    className="w-full"
                />
                <p className="text-2xl font-semibold">
                    {t('auth.onboard.address')}
                </p>
                <FormField
                    name="address"
                    register={register}
                    type="text"
                    label={t('auth.onboard.address')}
                    placeholder={t('auth.onboard.addressPlaceholder')}
                    errors={errors}
                    required
                    className="w-full"
                />
                <FormField
                    name="address_2"
                    register={register}
                    type="text"
                    label={t('auth.onboard.address2')}
                    placeholder={t('auth.onboard.address2Placeholder')}
                    errors={errors}
                    className="w-full"
                />
                <div className="flex gap-6">
                    <FormField
                        name="city"
                        register={register}
                        type="text"
                        label={t('auth.onboard.city')}
                        placeholder={t('auth.onboard.cityPlaceholder')}
                        errors={errors}
                        required
                        className="w-full"
                    />
                    <FormField
                        name="zip_code"
                        register={register}
                        type="text"
                        label={t('auth.onboard.zipCode')}
                        placeholder={t('auth.onboard.zipCodePlaceholder')}
                        errors={errors}
                        required
                        className="w-full"
                    />
                </div>
                <SelectField
                    title={t('auth.onboard.country')}
                    name="state"
                    required
                    register={register}
                    items={countries}
                    errors={errors}
                    className="w-full"
                />
                <PrimaryButton
                    label={t('common.save')}
                    className="ml-auto w-fit"
                />
            </form>
            <OnboardSuccessDialog
                onClickHandler={() => {
                    setIsDialogOpen(false);
                    navigate('/');
                }}
                isDialogOpen={isDialogOpen}
            />
        </div>
    );
};

export default RegisteredCompanyForm;
