import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

type Props = {
    onChange: (event: any) => void;
};

const CardPayment = ({ onChange }: Props) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold text-[#1B1C19]">
                    {t('checkout.cardNumber')}
                </label>
                <div className="w-1/2 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191]">
                    <CardNumberElement
                        onChange={onChange}
                        options={CARD_ELEMENT_OPTIONS}
                    />
                </div>
            </div>
            <div className="flex flex-col gap-2 ">
                <label className="text-sm font-semibold text-[#1B1C19]">
                    {t('checkout.validThru')}
                </label>
                <div className="w-1/2 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191]">
                    <CardExpiryElement
                        onChange={onChange}
                        options={CARD_ELEMENT_OPTIONS}
                    />
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold text-[#1B1C19]">
                    CVC
                </label>
                <div className="w-1/2 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191]">
                    <CardCvcElement
                        onChange={onChange}
                        options={CARD_ELEMENT_OPTIONS}
                    />
                </div>
            </div>
        </div>
    );
};

export const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            iconColor: 'black',
            color: 'black',
            fontSize: '18px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#708191',
            },
        },
        invalid: {
            iconColor: '#fa004f',
            color: '#fa004f',
        },
    },
};

export default CardPayment;
