import PrimaryButton from 'src/components/buttons/PrimaryButton';
import { StepModel } from '../stepper/Stepper.interface';
import { useTranslation } from 'react-i18next';
import xMark from 'src/assets/icons/xmark-regular.svg';
import { useState } from 'react';
import { Product } from 'src/pages/marketplace/Marketplace.interface';
import { removeFromCart } from 'src/utils/cart/cartController';
import { toast } from 'react-toastify';

type Props = {
    steps: Array<StepModel>;
    activeStep: number;
    navigateTo: (step: number, currentStepValid?: boolean) => void;
    onStepChange: (step: number) => void;
    setCurrentStepValid: (valid: boolean) => void;
    products: Array<Product>;
    setProductList: any;
};

const ProductsOverviewStep = ({
    setCurrentStepValid,
    steps,
    activeStep,
    navigateTo,
    onStepChange,
    products,
    setProductList,
}: Props) => {
    const { t } = useTranslation();
    const [isAgbChecked, setIsAgbChecked] = useState<boolean>(false);

    const removeProduct = (productId?: number) => {
        if (productId) {
            let tempProductList: Array<Product> = products.filter(
                (item: Product) => item.id !== productId
            );

            removeFromCart(productId);
            setProductList(tempProductList);
        }
    };

    const onSubmitClickHandler = () => {
        if (isAgbChecked) {
            setCurrentStepValid(true);
            navigateTo(activeStep + 1, true);
        } else {
            toast.error(t('checkout.pleaseCheckTerms'));
        }
    };

    return (
        <div className="flex h-full flex-col gap-6">
            <h2 className="text-2xl font-semibold text-[#24245F]">
                {t('checkout.productList')}
            </h2>
            <div className="flex flex-col gap-6">
                {products && products.length === 0 && (
                    <div>{t('common.noProductsFound')}</div>
                )}
                {products &&
                    products.map((item: Product) => {
                        return (
                            <div
                                key={item.id}
                                className="flex flex-col items-center gap-3 border-b-2 pb-4 lg:flex-row"
                            >
                                <div className="flex w-full items-center justify-between self-start sm:items-start lg:w-1/12">
                                    {/* <img
                                    className="h-14 w-14 rounded-xl"
                                    src={item.image}
                                    alt="product"
                                /> */}
                                    <button
                                        className="right-0 top-0 ml-auto flex items-center justify-center rounded-full sm:hidden lg:w-1/12"
                                        onClick={() => {}}
                                    >
                                        <img
                                            src={xMark}
                                            className="h-5 w-5"
                                            alt="x-mark"
                                        />
                                    </button>
                                </div>

                                <div className="flex w-full items-center gap-2">
                                    <div className="flex flex-col lg:w-9/12">
                                        <p className="text-base font-semibold text-[#24245F]">
                                            {item.title}
                                        </p>
                                        <p className="text-sm font-normal text-[#24245F]">
                                            {item.short_description}
                                        </p>
                                    </div>
                                    <div className="ml-auto flex flex-col lg:w-2/12">
                                        <p className="text-xl font-semibold text-[#24245F]">
                                            {item.price &&
                                                item.price.toFixed(2)}
                                        </p>
                                        <p className="text-sm font-normal text-[#24245F]">
                                            {t('checkout.plusVat')}
                                        </p>
                                    </div>
                                    <button
                                        className="right-0 top-0 ml-auto hidden items-center justify-center rounded-full sm:flex lg:w-1/12"
                                        onClick={() => removeProduct(item.id)}
                                    >
                                        <img
                                            src={xMark}
                                            className="h-5 w-5"
                                            alt="x-mark"
                                        />
                                    </button>
                                </div>
                            </div>
                        );
                    })}
            </div>

            <div className="mt-auto flex items-center gap-2">
                <input
                    type="checkbox"
                    className="h-5 w-5"
                    onChange={() => setIsAgbChecked(!isAgbChecked)}
                />
                <p className="text-[#24245F]">{t('auth.signUp.IAccept')}</p>
                <a
                    className="text-center text-[#06D7A0] hover:text-[#06d79fb4]"
                    href={'https://digitale-gruendung.de/agb/'}
                    target="_blank"
                    rel="noreferrer"
                >
                    {t('common.termsConditions')}
                </a>
            </div>

            <div className="flex flex-col items-center justify-between gap-5 xs:flex-row">
                {activeStep === 1 && (
                    <button
                        className="mx-auto text-base font-semibold text-[#06D7A0] hover:text-[#06d79fb4] xs:ml-0 xs:mr-auto"
                        onClick={() => {}}
                    >
                        {t('common.cancel')}
                    </button>
                )}
                {activeStep !== steps.length && (
                    <PrimaryButton
                        className="mx-auto xs:ml-auto xs:mr-0"
                        label={t('common.next')}
                        onClick={onSubmitClickHandler}
                    />
                )}
            </div>
        </div>
    );
};

export default ProductsOverviewStep;
