import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { lngs } from 'src/const/language';
import { LanguageContext } from 'src/context/LanguageContext';

type Props = {
    isFullLanguage?: boolean;
};

const LanguageSwitcher = ({ isFullLanguage }: Props) => {
    const { i18n } = useTranslation();
    const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);

    return (
        <div>
            {isFullLanguage ? (
                <select
                    className="h-6"
                    onChange={(event) => {
                        i18n.changeLanguage(event.target.value);
                        setCurrentLanguage(event.target.value);
                    }}
                >
                    {Object.keys(lngs).map((lng) => (
                        <option
                            className="h-6"
                            selected={lng === currentLanguage}
                            key={lng}
                            value={lng}
                        >
                            {lng ? lngs[lng].nativeName : ''}
                        </option>
                    ))}
                </select>
            ) : (
                <select
                    className="h-6"
                    onChange={(event) => {
                        i18n.changeLanguage(event.target.value);
                        setCurrentLanguage(event.target.value);
                    }}
                >
                    {Object.keys(lngs).map((lng) => (
                        <option
                            className="h-6"
                            selected={lng === currentLanguage}
                            key={lng}
                            value={lng}
                        >
                            {lng ? lng.toUpperCase() : ''}
                        </option>
                    ))}
                </select>
            )}
        </div>
    );
};

export default LanguageSwitcher;
