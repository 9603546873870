import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { RouteUrl, protectedRoutesUrls } from 'src/const/routesUrls';

const Sidebar = () => {
    const location = useLocation();
    const [routeList, setRouteList] =
        useState<Array<RouteUrl>>(protectedRoutesUrls);
    const { t } = useTranslation();

    useEffect(() => {
        setRouteList(protectedRoutesUrls);
    }, [location.pathname]);

    return (
        <div className="hidden w-full flex-col gap-7 sm:flex">
            {routeList &&
                routeList.map((route: RouteUrl) => {
                    if (route.showInMenu) {
                        return (
                            <Link
                                key={route.name}
                                className={
                                    location.pathname === route.url
                                        ? 'sidebar-item active group'
                                        : 'sidebar-item group'
                                }
                                to={route.url}
                            >
                                <div
                                    className={
                                        location.pathname === route.url
                                            ? 'flex w-full items-center gap-3 border-r-4 border-r-[#4ABFBF] pl-1 group-hover:border-r-4 group-hover:border-r-[#4ABFBF] lg:pl-6'
                                            : 'flex w-full items-center gap-3 pl-1 group-hover:border-r-4 group-hover:border-r-[#4ABFBF] lg:pl-6 '
                                    }
                                >
                                    <img
                                        className="sidebar-icon h-3 w-3 sm:w-6 lg:h-6"
                                        src={route.icon}
                                        alt="Dashboard"
                                    />
                                    <p className="text-sm  text-[#708191] group-hover:text-[#1B1C19] lg:text-base">
                                        {t(`${route.label}`)}
                                    </p>
                                </div>
                            </Link>
                        );
                    }
                    return null;
                })}
        </div>
    );
};

export default Sidebar;
