import { ReactNode, useEffect, useState } from 'react';
import Sidebar from '../sidebar/Sidebar';
import logo from 'src/assets/icons/logo.svg';
import cross from 'src/assets/icons/cross-grey.svg';
import { MenuContext } from 'src/context/MenuContext';
import { NotificationContext } from 'src/context/NotificationContext';
import { RouteUrl, protectedRoutesUrls } from 'src/const/routesUrls';
import { Link, useLocation } from 'react-router-dom';
import { NotificationModel } from 'src/interfaces/Notification.interface';
import { useTranslation } from 'react-i18next';
import { AppUser } from 'src/interfaces/AppUser.interface';
import { getUserInfo } from 'src/utils/auth';
import { UserContext } from 'src/context/UserContext';
import { CartContext } from 'src/context/CartContext';
import { Product } from 'src/pages/marketplace/Marketplace.interface';
import { getCart } from 'src/utils/cart/cartController';

type Props = {
    children: ReactNode;
};

const Layout = ({ children }: Props) => {
    const [notificationList, setNotificationList] = useState<
        Array<NotificationModel>
    >([]);
    const [productList, setProductList] = useState<Array<Product>>([]);
    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
    const [appUser, setAppUser] = useState<AppUser>({});
    const location = useLocation();
    const { t } = useTranslation();

    // useEffect(() => {
    //     const fetchNotifications = async () => {
    //         try {
    //             const resp = await client.get(GET_NOTIFICATIONS);
    //             if (resp && resp.data && resp.status === 200) {
    //                 setNotificationList(resp.data);
    //             }
    //         } catch (error) {
    //             console.log(error);
    //             toast.error('Something went wrong!');
    //         }
    //     };

    //     let interval = setInterval(() => {
    //         fetchNotifications();
    //     }, 60000);

    //     fetchNotifications();

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, []);

    useEffect(() => {
        let cart: Array<Product> = getCart();
        setProductList(cart);
    }, []);

    useEffect(() => {
        let user = getUserInfo();
        if (user) {
            setAppUser(JSON.parse(user));
        }
    }, []);

    return (
        <div className="flex">
            <div className="mx-auto hidden flex-col sm:flex sm:w-[20vw]">
                <img
                    className="mx-auto my-8 hidden h-20 w-2/3 sm:flex lg:w-1/2"
                    src={logo}
                    alt="Digitale gruendung"
                />
                <Sidebar />
            </div>
            <div className="min-h-screen w-full bg-[#F7F8F7] px-5 py-8 xs:px-10 sm:w-[80vw]">
                <NotificationContext.Provider
                    value={{ notificationList, setNotificationList }}
                >
                    <MenuContext.Provider
                        value={{ isMenuOpened, setIsMenuOpened }}
                    >
                        <UserContext.Provider value={{ appUser, setAppUser }}>
                            <CartContext.Provider
                                value={{ productList, setProductList }}
                            >
                                {children}
                            </CartContext.Provider>
                        </UserContext.Provider>
                    </MenuContext.Provider>
                </NotificationContext.Provider>
            </div>
            <div
                className={`fixed left-0 flex h-screen w-screen flex-col bg-[#fff] transition-all duration-[1000ms] sm:hidden ${
                    isMenuOpened ? 'top-0' : 'top-[-1000px]'
                }`}
            >
                <div className="flex items-center justify-between p-4">
                    <img className="h-7 w-1/4" src={logo} alt="d_tre" />
                    <button
                        className="self-end"
                        onClick={() => setIsMenuOpened(false)}
                    >
                        <img src={cross} alt="cross" className="h-6 w-6" />
                    </button>
                </div>

                <div className="flex flex-col ">
                    {protectedRoutesUrls &&
                        protectedRoutesUrls.map((route: RouteUrl) => {
                            if (route.showInMenu) {
                                return (
                                    <Link
                                        key={route.name}
                                        className={
                                            location.pathname === route.url
                                                ? 'sidebar-item active group p-4'
                                                : 'sidebar-item group p-4'
                                        }
                                        to={route.url}
                                    >
                                        <div
                                            className={
                                                location.pathname === route.url
                                                    ? 'flex w-full items-center gap-3 border-r-4 border-r-[#06D7A0] pl-1 group-hover:border-r-4 group-hover:border-r-[#06D7A0] lg:pl-6'
                                                    : 'flex w-full items-center gap-3 pl-1 group-hover:border-r-4 group-hover:border-r-[#06D7A0] lg:pl-6 '
                                            }
                                        >
                                            <img
                                                className="sidebar-icon h-6 w-6 "
                                                src={route.icon}
                                                alt="Dashboard"
                                            />
                                            <p className=" group-hover:text-[#1B1C19]text-base text-[#708191]">
                                                {t(`${route.label}`)}
                                            </p>
                                        </div>
                                    </Link>
                                );
                            }
                            return null;
                        })}
                </div>
            </div>
        </div>
    );
};

export default Layout;
