type Props = {
    label: string;
    onClick?: () => void;
    className?: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
    buttonRef?: any;
};

const SecondaryButton = ({
    label,
    onClick,
    className,
    type,
    buttonRef,
}: Props) => {
    return (
        <button
            className={
                'text-base font-semibold text-[#06D7A0] hover:text-[#06d79fb4] ' +
                className
            }
            type={type}
            ref={buttonRef}
            onClick={onClick}
        >
            {label}
        </button>
    );
};

export default SecondaryButton;
