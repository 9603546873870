import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CartContext } from 'src/context/CartContext';
import { Product } from 'src/pages/marketplace/Marketplace.interface';
import { addToCart } from 'src/utils/cart/cartController';
import PrimaryButton from './PrimaryButton';

type Props = {
    product: Product;
    label?: string;
    className?: string;
};

const BuyButton = ({ product, className, label }: Props) => {
    const { t } = useTranslation();
    const { productList, setProductList } = useContext(CartContext);

    const onBuyClickHandler = () => {
        if (!productList.find((item: Product) => item.id === product.id)) {
            addToCart({ ...product, price: Number(product.price) });

            let tempProductList: Array<Product> = [
                ...productList,
                { ...product, price: Number(product.price) },
            ];

            setProductList(tempProductList);
            toast.success(t('checkout.addedToCart'));
        } else {
            toast.warn(t('checkout.alreadyInCart'));
        }
    };

    return (
        <PrimaryButton
            label={label ? label : t('common.buy')}
            className={className}
            onClick={onBuyClickHandler}
        />
    );
};

export default BuyButton;
