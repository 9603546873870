import { Navigate, Outlet } from 'react-router-dom';
import Layout from '../layout/Layout';
import { getSession } from 'src/utils/auth';

const ValidDataProtectedRoutes = () => {
    const session = getSession();
    if (session.validData) {
        return (
            <Layout>
                <Outlet />
            </Layout>
        );
    } else {
        return <Navigate to="/intro" />;
    }
};

export default ValidDataProtectedRoutes;
