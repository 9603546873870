import { useForm } from 'react-hook-form';
import { ResetPasswordData } from './Authentication.interface';
import axios from 'axios';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import logo from 'src/assets/images/logo.svg';
import FormField from 'src/components/formField/FormField';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import { RESET_PASSWORD } from 'src/const/apiUrls';

type Props = {};

const ResetPassword = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ResetPasswordData>();

    const onSubmit = async (data: ResetPasswordData) => {
        let token = searchParams ? searchParams.get('token') : null;

        if (data.password !== data.repeatPassword) {
            toast.error(t('page.settings.passwordDontMatch'));
            return;
        }

        try {
            let resp = await axios.post(RESET_PASSWORD, {
                password: data.password,
                token: token,
            });

            if (resp.data && resp.data.success) {
                toast.success(t('page.settings.successPasswordChange'));
                reset();
                navigate('/sign-in');
            }
        } catch (error: any) {
            console.log(error);
            toast.error(error.message);
        }
    };

    return (
        <div className="min-w-screen flex min-h-screen items-center justify-center bg-[#4ABFBF]">
            <div className="my-5 flex w-11/12 flex-col rounded-xl bg-[#FFF] sm:my-0 sm:flex-row lg:w-3/5">
                <div className="flex w-full flex-col p-8 sm:w-1/2">
                    <h1 className="text-2xl font-semibold leading-10 text-[#24245F]">
                        {t('auth.recoverPassword.resetPassword')}
                    </h1>
                    <p className="pb-10 text-base leading-7 text-[#24245F]">
                        {t('auth.recoverPassword.resetPasswordSubtitle')}
                    </p>

                    <div className="w-full">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="flex w-full flex-col gap-4"
                        >
                            <FormField
                                className="w-full"
                                name="password"
                                register={register}
                                type="changePassword"
                                label={t('page.settings.newPassword')}
                                placeholder={t(
                                    'page.settings.enterNewPassword'
                                )}
                                required
                                errors={errors}
                            />
                            <FormField
                                name="repeatPassword"
                                className="w-full"
                                register={register}
                                type="changePassword"
                                label={t('page.settings.repeatPassword')}
                                placeholder={t(
                                    'page.settings.enterRepeatPassword'
                                )}
                                required
                                errors={errors}
                            />
                            <p className="text-sm text-[#708191] ">
                                {t('page.settings.passwordInfo1')}
                                <span className="font-semibold">
                                    {' '}
                                    {t('page.settings.passwordInfo2')}
                                </span>{' '}
                                {t('page.settings.passwordInfo3')}
                                <span className="font-semibold">
                                    {t('page.settings.passwordInfo4')}
                                </span>{' '}
                                {t('page.settings.passwordInfo5')}
                                <span className="font-semibold">
                                    {t('page.settings.passwordInfo6')}
                                </span>
                                {t('page.settings.passwordInfo7')}
                                <span className="font-semibold">
                                    {t('page.settings.passwordInfo8')}
                                </span>
                                {t('page.settings.passwordInfo9')}
                            </p>
                            <div className="flex gap-8">
                                <PrimaryButton
                                    type={'submit'}
                                    label={t('common.submit')}
                                    className="w-fit"
                                />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="flex w-full flex-col items-center justify-between gap-5 p-8 sm:w-1/2">
                    <img src={logo} alt="logo" width={170} height={50} />
                    <div className="flex flex-col gap-6">
                        <h2 className="text-center text-xl font-semibold leading-7 text-[#24245F]">
                            {t('auth.recoverPassword.doDifferentAction')}
                        </h2>
                        <p className="text-center text-base leading-7 text-[#24245F]">
                            {t('auth.recoverPassword.youCanNow')}
                            <Link
                                className="px-2 text-center font-semibold text-[#06D7A0] hover:text-[#06d79fb4]"
                                to={'/sign-in'}
                            >
                                {t('auth.recoverPassword.signIn')}
                            </Link>

                            {t('auth.recoverPassword.or')}
                            <Link
                                className="px-2 text-center font-semibold text-[#06D7A0] hover:text-[#06d79fb4]"
                                to={'/sign-up'}
                            >
                                {t('auth.signUp.createAccount')}
                            </Link>
                        </p>
                    </div>
                    <div
                        className={
                            'flex flex-col justify-center xl:flex-row xl:justify-around'
                        }
                    >
                        <a
                            className="px-8 py-3 text-center text-[#06D7A0] hover:text-[#06d79fb4]"
                            href={'https://digitale-gruendung.de/datenschutz/'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('common.privacyPolicy')}
                        </a>
                        <a
                            className="px-8 py-3 text-center text-[#06D7A0] hover:text-[#06d79fb4]"
                            href={'https://digitale-gruendung.de/agb/'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('common.termsConditions')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
