import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'src/components/buttons/PrimaryButton';

type Props = {
    isDialogOpen: boolean;
    onClickHandler: () => void;
};

const OnboardSuccessDialog = ({ isDialogOpen, onClickHandler }: Props) => {
    const { t } = useTranslation();
    return (
        <Dialog open={isDialogOpen} fullWidth>
            <div className="flex w-full flex-col gap-6 px-6 py-10">
                <div className="flex w-full flex-col gap-4">
                    <h3 className="text-xl font-semibold text-[#24245F] sm:text-base lg:text-2xl">
                        {t('auth.onboard.onboardSuccess')}
                    </h3>
                    <p className="text-base text-[#06D7A0]">
                        {t('auth.onboard.successfullyOnboarded')}
                    </p>
                </div>

                <PrimaryButton
                    label={t('auth.onboard.goToHomepage')}
                    onClick={onClickHandler}
                />
            </div>
        </Dialog>
    );
};

export default OnboardSuccessDialog;
