import { UseFormRegister } from 'react-hook-form';
import { SelectFieldData } from './SelectField.interface';

type Props = {
    title: string;
    name: string;
    items: Array<SelectFieldData>;
    register?: UseFormRegister<any>;
    required?: boolean;
    errors?: any;
    className?: string;
    onValueChangeHandler?: (event: any) => void;
    value?: string;
    note?:string
};

const SelectField = ({
    title,
    items,
    name,
    register,
    required,
    errors,
    className,
    onValueChangeHandler,
    value,
    note
}: Props) => {
    return (
        <div className={'flex flex-col gap-2 ' + className}>
            <label className="text-sm font-bold text-[#1B1C19]">
                {title}
                <span
                    className={
                        required ? 'text-sm font-bold text-[#FF7E66]' : 'hidden'
                    }
                >
                    {' '}
                    *
                </span>
            </label>
            {register !== null && register !== undefined && onValueChangeHandler === null && (
                <select
                    className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    {...register(name, {
                        required: {
                            value: required ? required : false,
                            message: 'Required Field',
                        },
                    })}
                >
                    {items &&
                        items.map((item: SelectFieldData) => {
                            return (
                                <option key={item.value} value={item.value}>
                                    {item.label}
                                </option>
                            );
                        })}
                </select>
            )}
            {register === null ||
                (register === undefined && (
                    <select
                        name={name}
                        value={value ? value : ''}
                        onChange={onValueChangeHandler}
                        className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    >
                        {items &&
                            items.map((item: SelectFieldData) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                    </select>
                ))}
            {register !== null && register !== undefined && 
                (onValueChangeHandler !== null  && (
                    <select
                        {...register(name, {
                            required: {
                                value: required ? required : false,
                                message: 'Required Field',
                            },
                        })}
                        name={name}
                        onChange={onValueChangeHandler}
                        className="focus:border-1 rounded-lg border border-solid border-[#DDD] bg-[#FFF] px-4 py-2 text-[#708191] focus:border-[#06D7A0]"
                    >
                        {items &&
                            items.map((item: SelectFieldData) => {
                                return (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                );
                            })}
                    </select>
                ))}
            {errors && (
                <span className="text-red-700">
                    {errors && errors[`${name}`] && errors[`${name}`].message}
                </span>
            )}
            <span className='mt-[-15px] text-[#06D7A0] text-sm'>{note}</span>
        </div>
    );
};

export default SelectField;
