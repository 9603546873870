import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import FormField from 'src/components/formField/FormField';
import { ChangePasswordDTO } from '../Settings.interface';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { client } from 'src/utils/client';
import { CHANGE_PASSWORD } from 'src/const/apiUrls';

type Props = {};

const ChangePassword = (props: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<ChangePasswordDTO>();

    const onSubmit = async (data: ChangePasswordDTO) => {
        if (data.password === data.old_password) {
            toast.error(t('page.settings.passwordAlreadyInUse'));
            return;
        }

        if (data.password !== data.password_repeat) {
            toast.error(t('page.settings.passwordDontMatch'));
            return;
        }
        try {
            let resp = await client.post(CHANGE_PASSWORD, {
                old_password: data.old_password,
                new_password: data.password,
            });
            if (resp.data && resp.data.success) {
                toast.success(t('page.settings.successPasswordChange'));
                reset();
                navigate('/sign-in');
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    };

    return (
        <div className="flex w-full flex-1 flex-col gap-6 rounded-2xl border px-4 py-6">
            <h3 className="text-xl font-semibold text-[#1B1C19]">
                {t('page.settings.changePassword')}
            </h3>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex w-full flex-col gap-6"
            >
                <FormField
                    name="old_password"
                    register={register}
                    type="password"
                    label={t('page.settings.currentPassword')}
                    placeholder={t('page.settings.enterCurrentPassword')}
                    className="w-full"
                    required
                    errors={errors}
                />
                <FormField
                    className="w-full"
                    name="password"
                    register={register}
                    type="changePassword"
                    label={t('page.settings.newPassword')}
                    placeholder={t('page.settings.enterNewPassword')}
                    required
                    errors={errors}
                />
                <FormField
                    name="password_repeat"
                    className="w-full"
                    register={register}
                    type="changePassword"
                    label={t('page.settings.repeatPassword')}
                    placeholder={t('page.settings.enterRepeatPassword')}
                    required
                    errors={errors}
                />
                <p className="text-sm text-[#708191] ">
                    {t('page.settings.passwordInfo1')}
                    <span className="font-semibold">
                        {' '}
                        {t('page.settings.passwordInfo2')}
                    </span>{' '}
                    {t('page.settings.passwordInfo3')}
                    <span className="font-semibold">
                        {t('page.settings.passwordInfo4')}
                    </span>{' '}
                    {t('page.settings.passwordInfo5')}
                    <span className="font-semibold">
                        {t('page.settings.passwordInfo6')}
                    </span>
                    {t('page.settings.passwordInfo7')}
                    <span className="font-semibold">
                        {t('page.settings.passwordInfo8')}
                    </span>
                    {t('page.settings.passwordInfo9')}
                </p>
                <div className="flex justify-end gap-8">
                    <PrimaryButton
                        type={'submit'}
                        label={t('common.submit')}
                        className="w-fit"
                    />
                </div>
            </form>
        </div>
    );
};

export default ChangePassword;
