import { Slider, styled } from '@mui/material';

export const CustomSlider = styled(Slider)({
    color: '#06D7A0',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.4,
        fontSize: 12,
        background: 'unset',
        padding: '4px 6px',
        width: 35,
        height: 35,
        borderRadius: '4px',
        border: '1px solid #24245f33',
        color: '#24245f80',
        backgroundColor: '#fff',
        transformOrigin: 'bottom left',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(0%, -100%) rotate(0deg) scale(1)',
        },
    },
});
