import { createContext } from 'react';
import { Product } from 'src/pages/marketplace/Marketplace.interface';

export type CartContent = {
    productList: Array<Product>;
    setProductList: (productList: Array<Product>) => void;
};

export const CartContext = createContext<CartContent>({
    productList: [],
    setProductList: () => {},
});
