import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Coach, Product } from 'src/pages/marketplace/Marketplace.interface';
import { CircularProgress } from '@mui/material';
import productImg from 'src/assets/images/product-placeholder.png';
import PackageDetailsDialog from 'src/components/dialogs/PackageDetailsDialog';
import { client } from 'src/utils/client';
import { GET_COACHINGS } from 'src/const/apiUrls';
import { LanguageContext } from 'src/context/LanguageContext';

type Props = {};

const MostBookedCoachings = (props: Props) => {
    const { t } = useTranslation();
    const [coachings, setCoachings] = useState<Array<Product>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const language = useContext(LanguageContext);

    useEffect(() => {
        const fetchCoachings = async () => {
            try {
                const coachingResp = await client.get(
                    GET_COACHINGS(language.currentLanguage) + '&amount=4'
                );
                if (
                    coachingResp &&
                    coachingResp.data &&
                    coachingResp.data.data
                ) {
                    setCoachings(coachingResp.data.data);
                    setLoading(false);
                }
            } catch (error) {
                console.log('Booked coachings couldnt load.');
                setLoading(false);
            }
        };
        fetchCoachings();
    }, [language.currentLanguage]);

    return (
        <div className="rounded-xl bg-[#fff] p-6">
            <h1 className="p-2 text-lg font-bold text-[#24245f99]">
                {t('common.mostBookedCoachings')}
            </h1>
            <div className="flex flex-col gap-3">
                {coachings &&
                    coachings.length > 0 &&
                    coachings.map((item: Product) => {
                        return (
                            <div
                                key={item.id}
                                className="flex flex-col items-center gap-1 xs:flex-row  xs:gap-4 "
                            >
                                <div className="p-2">
                                    <div>
                                        {item.image && (
                                            <img
                                                className="h-24 w-24 rounded-2xl border"
                                                src={item.image}
                                                alt="seminar-img"
                                            />
                                        )}
                                        {!item.image && (
                                            <img
                                                className="h-24 rounded-2xl border"
                                                src={productImg}
                                                alt="seminar-placeholder"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 p-2">
                                    <button
                                        onClick={() => {
                                            setDialogOpen(true);
                                            setSelectedProduct(item);
                                        }}
                                        className=" overflow-hidden border-0 bg-none text-start text-sm font-normal text-[#00DA90] hover:underline focus:outline-none"
                                    >
                                        {item.title}
                                    </button>
                                    <div className="flex flex-wrap gap-1 text-xs font-medium text-[#24245F]">
                                        {t('common.trainerCoach')}:
                                        {item.trainer_coach
                                            ? item.trainer_coach.map(
                                                  (item: Coach) => {
                                                      return (
                                                          <span key={item.name}>
                                                              {item.name}
                                                          </span>
                                                      );
                                                  }
                                              )
                                            : ''}
                                    </div>
                                    <p className="text-sm font-semibold text-[#24245F]">
                                        {'€ ' + item.price}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
            </div>
            {loading && (
                <CircularProgress
                    style={{
                        position: 'relative',
                        top: '30vh',
                        left: '50%',
                        color: '#06D7A0',
                    }}
                />
            )}
            {coachings && coachings.length === 0 && !loading && (
                <div className="my-auto flex h-full w-full items-center justify-center p-4 pt-0 text-base font-bold text-[#06D7A0]">
                    {t('common.comingSoon')}
                </div>
            )}
            <PackageDetailsDialog
                product={selectedProduct}
                dialogOpen={dialogOpen}
                onClose={(event, reason) => {
                    setDialogOpen(false);
                }}
                setDialogOpen={setDialogOpen}
            />
        </div>
    );
};

export default MostBookedCoachings;
