import { useTranslation } from 'react-i18next';
import { Product } from 'src/pages/marketplace/Marketplace.interface';
import { useContext } from 'react';
import { LanguageContext } from 'src/context/LanguageContext';
import productImg from 'src/assets/images/product-placeholder.png';
import BuyButton from '../buttons/BuyButton';

type Props = {
    product: Product;
    showBuyButton?: boolean;
    moreButtonOnClick?: () => void;
};

const PackageCard = ({ product, showBuyButton, moreButtonOnClick }: Props) => {
    const { t } = useTranslation();
    const { currentLanguage } = useContext(LanguageContext);

    let summaryDescription =
        product && product.short_description.length > 150
            ? product.short_description.substring(0, 149) + '...'
            : product.short_description;

    return (
        <div className="flex h-full flex-col rounded-2xl bg-[#fff]">
            <div className="rounded-t-2xl ">
                <p className="relative left-5 top-[160px] w-fit rounded bg-[#fff] px-6 py-3 text-xs text-[#4ABFBF] shadow-lg shadow-[#0012330d]">
                    {product.category[0].name}
                </p>
                {product && product.image && (
                    <img
                        className="mx-auto h-36 w-full rounded-t-2xl border-2 border-b-0 border-[#24245f0a]"
                        src={product.image}
                        alt="seminar-img"
                    />
                )}
                {product && !product.image && (
                    <img
                        className=" mx-auto h-36 w-full rounded-t-2xl border-2 border-b-0 border-[#24245f0a]"
                        src={productImg}
                        alt="seminar-placeholder"
                    />
                )}
            </div>
            <div className="flex h-full flex-col rounded-b-2xl border-2 border-[#24245f0a] px-6 py-3">
                <div className="flex h-full flex-col gap-4 pb-3 pt-6">
                    <h2 className="h-[60px] overflow-hidden text-sm font-semibold text-[#24245F] lg:h-[40px] xl:h-[60px]">
                        {product.title}
                    </h2>
                    <p className="text-xs text-[#24245f99]">
                        {product.type
                            ? t(
                                  `page.marketplace.${product.type}`
                              ).toUpperCase()
                            : product.type}
                    </p>
                    <p className=" text-xs text-[#24245F]">
                        {summaryDescription}
                    </p>
                    <p className="mt-auto text-lg font-semibold text-[#24245F]">
                        {product.price
                            ? '€ ' +
                              product.price.toLocaleString(currentLanguage)
                            : '€ ' + product.price}
                    </p>
                </div>
                <div className="mt-auto flex w-full items-center gap-4 pb-3">
                    {showBuyButton && (
                        <BuyButton
                            product={product}
                            className="px-[30px] py-[12px] text-xs"
                        />
                    )}
                    <button
                        onClick={moreButtonOnClick}
                        className="w-fit border-0 bg-none text-xs font-semibold text-[#00DA90] hover:underline focus:outline-none"
                    >
                        {t('common.more')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PackageCard;
