import { createContext } from 'react';
import { AppUser } from 'src/interfaces/AppUser.interface';

export type UserContent = {
    appUser: AppUser;
    setAppUser: (user: AppUser) => void;
};

export const UserContext = createContext<UserContent>({
    appUser: {},
    setAppUser: () => {},
});
