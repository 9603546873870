import { useState } from 'react';
import './App.css';
import Router from './components/router/Router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LanguageContext } from './context/LanguageContext';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function App() {
    const [currentLanguage, setCurrentLanguage] = useState<string>('de');
    return (
        <div>
            <LanguageContext.Provider
                value={{ currentLanguage, setCurrentLanguage }}
            >
                <ToastContainer />
                <QueryClientProvider client={queryClient}>
                    <Router />
                </QueryClientProvider>
            </LanguageContext.Provider>
        </div>
    );
}

export default App;
