import { Navigate, Outlet } from 'react-router-dom';
import { getSession } from 'src/utils/auth';

const ProtectedRoutes = () => {
    const session = getSession();

    if (session.accessToken) {
        return <Outlet />;
    } else {
        return <Navigate to="/sign-in" />;
    }
};

export default ProtectedRoutes;
