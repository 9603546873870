import {
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
} from '@mui/x-data-grid';
import { Order } from './Orders.interface';
import StatusComponent from './StatusComponent';
import { Link } from 'react-router-dom';
import arrow from 'src/assets/icons/MaterialSymbolsArrowCircleRightRounded.svg';

export const getColumns = (locale: string): GridColDef[] => {
    return [
        {
            field: 'id',
            headerName: locale === 'en' ? 'Order #' : 'Bestellung',
            flex: 0.5,
            minWidth: 90,
            headerClassName: 'text-xs text-[#708191]',
        },
        {
            field: 'date_created',
            type: 'date',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return '';
                }
                try {
                    let date = new Date(params.value);
                    return `${date.toLocaleString('de')}`;
                } catch (error) {
                    return params.value;
                }
            },
            headerName: locale === 'en' ? 'Date ordered' : 'Bestelldatum',
            headerClassName: 'text-xs text-[#708191]',
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 110,
            headerClassName: 'text-xs text-[#708191]',
            renderCell: (params: GridRenderCellParams<Order>) => {
                return (
                    <StatusComponent status={params.value}></StatusComponent>
                );
            },
        },
        {
            field: 'payment_method',
            headerName: locale === 'en' ? 'Payment method' : 'Zahlungsmethode',
            flex: 1,
            minWidth: 80,
            headerClassName: 'text-xs text-[#708191]',
        },
        {
            field: 'total',
            headerName: locale === 'en' ? 'Amount' : 'Menge',
            type: 'number',
            flex: 1,
            minWidth: 100,
            headerClassName: 'text-xs text-[#708191]',
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                if (params.value == null) {
                    return '';
                }
                return `€${params.value}`;
            },
        },
        {
            field: 'action',
            headerName: '',
            sortable: false,
            flex: 1,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<Order>) => {
                return (
                    <Link
                        to={'/order/' + params.row.id}
                        className="ml-auto text-base text-[#06D7A0] hover:text-[#06d79fb4] "
                    >
                        <img src={arrow} alt="arrow" />
                    </Link>
                );
            },
        },
    ];
};
