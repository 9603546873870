import { useEffect, useState } from 'react';
import RegisteredCompanyForm from './RegisteredCompanyForm';
import { useTranslation } from 'react-i18next';
import IndividualForm from './IndividualForm';
import { client } from 'src/utils/client';
import { GET_COUNTRIES } from 'src/const/apiUrls';
import { SelectFieldData } from 'src/components/formField/SelectField.interface';
import {
    IndividualData,
    RegisteredCompanyData,
} from '../Authentication.interface';
import { generateRandomString } from 'src/utils/generateRandomString';

type Props = {
    defaultValuesIndividual?: IndividualData;
    defaultValuesRegistered?: RegisteredCompanyData;
    isOnboard: boolean;
    fetchUserData?: () => void;
};

const OnboardForm = ({
    defaultValuesIndividual,
    defaultValuesRegistered,
    isOnboard,
    fetchUserData,
}: Props) => {
    const [isIndividual, setIsIndividual] = useState<boolean>(true);
    const { t } = useTranslation();
    const [countries, setCountries] = useState<Array<SelectFieldData>>([]);

    useEffect(() => {
        let fetchCountries = async () => {
            try {
                let resp = await client.get(GET_COUNTRIES);

                if (resp && resp.data && resp.data.data) {
                    let tempCountries = resp.data.data;
                    let keys: Array<string> = Object.keys(tempCountries);
                    let selectFieldCountries: Array<SelectFieldData> = keys.map(
                        (key: string) => {
                            return {
                                value: key,
                                label: tempCountries[key],
                            };
                        }
                    );
                    setCountries([
                        {
                            value: '',
                            label: '-',
                        },
                        ...selectFieldCountries,
                    ]);
                }
            } catch (error) {}
        };
        fetchCountries();
    }, []);

    return (
        <div className="flex flex-col gap-6 p-6">
            <div className="flex justify-center gap-4 ">
                <div
                    className={`b-2 flex w-1/2 items-center justify-center gap-3 rounded-3xl bg-[#F8F8F8] p-6 hover:cursor-pointer`}
                    onClick={() => setIsIndividual(true)}
                >
                    <div>
                        <input
                            className="h-6 w-6"
                            type="radio"
                            checked={isIndividual}
                            onChange={() => setIsIndividual(true)}
                        />
                    </div>

                    <div className="flex flex-col">
                        <p className="text-lg font-semibold text-[#24245F]">
                            {t('auth.onboard.individualUserTitle')}
                        </p>
                        <p className="text-sm font-normal text-[#24245F]">
                            {t('auth.onboard.individualUserSubtitle')}
                        </p>
                    </div>
                </div>
                <div
                    className="b-2 flex w-1/2 items-center justify-center gap-3 rounded-3xl bg-[#F8F8F8] p-6 hover:cursor-pointer"
                    onClick={() => setIsIndividual(false)}
                >
                    <div>
                        <input
                            className="h-6 w-6"
                            type="radio"
                            checked={!isIndividual}
                            onChange={() => setIsIndividual(false)}
                        />
                    </div>
                    <div className="flex flex-col">
                        <p className="text-lg font-semibold text-[#24245F]">
                            {t('auth.onboard.registeredCompanyTitle')}
                        </p>
                        <p className="text-sm font-normal text-[#24245F]">
                            {t('auth.onboard.registeredCompanySubtitle')}
                        </p>
                    </div>
                </div>
            </div>
            {isIndividual && (
                <IndividualForm
                    fetchUserData={fetchUserData}
                    isOnboardProcess={isOnboard}
                    countries={countries}
                    individualData={defaultValuesIndividual}
                />
            )}
            {!isIndividual && (
                <RegisteredCompanyForm
                    fetchUserData={fetchUserData}
                    isOnboardProcess={isOnboard}
                    countries={countries}
                    registeredCompanyData={defaultValuesRegistered}
                />
            )}
        </div>
    );
};

export default OnboardForm;
