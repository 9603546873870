import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ButtonLink from 'src/components/buttons/ButtonLink';
import { SignUpData } from './Authentication.interface';
import FormField from 'src/components/formField/FormField';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import logo from 'src/assets/images/logo.svg';
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import LanguageSwitcher from 'src/components/languageSwitcher/LanguageSwitcher';
import { SIGN_UP } from 'src/const/apiUrls';

const SignUp = () => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SignUpData>();
    const navigate = useNavigate();
    const [isAgbChecked, setIsAgbChecked] = useState<boolean>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const onSubmit = async (data: SignUpData) => {
        if (isAgbChecked) {
            setIsSubmitting(true);
            try {
                await axios.post(SIGN_UP, {
                    full_name: data.fullname,
                    email: data.email,
                    password: data.password,
                });
                setIsSubmitting(false);
                navigate('/confirm-account');
            } catch (error: any) {
                setIsSubmitting(false);
                console.log(error);
                let message = error.message;
                if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.data
                ) {
                    message = error.response.data.data;
                }
                toast.error(message);
            }
        } else {
            toast.error(t('auth.signUp.acceptTermsAndConditions'));
        }
    };

    return (
        <div className="min-w-screen flex min-h-screen items-center justify-center bg-[#4ABFBF]">
            <div className="my-5 flex w-11/12 flex-col rounded-xl bg-[#FFF] sm:my-0 sm:flex-row lg:w-3/5">
                <div className="flex w-full flex-col p-8 sm:w-1/2">
                    <h1 className="text-2xl font-semibold leading-10 text-[#24245F]">
                        {t('auth.signUp.createAccount')}
                    </h1>
                    <p className="pb-10 text-base leading-7 text-[#24245F]">
                        {t('auth.signUp.createAccountSubtitle')}
                    </p>
                    <div className="w-full">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="flex w-full flex-col gap-5"
                        >
                            <FormField
                                name="fullname"
                                register={register}
                                type="text"
                                label={t('auth.signUp.fullName')}
                                placeholder={t('auth.signUp.enterFullName')}
                                required
                                errors={errors}
                                className="w-full"
                            />

                            <FormField
                                name="email"
                                register={register}
                                type="email"
                                label={t('auth.login.email')}
                                placeholder={t('auth.login.enterYourEmail')}
                                required
                                errors={errors}
                                className="w-full"
                            />

                            <FormField
                                name="password"
                                register={register}
                                type="password"
                                label={t('auth.login.password')}
                                placeholder={t('auth.login.enterYourPassword')}
                                required
                                errors={errors}
                                className="w-full"
                            />

                            <div className="flex items-center gap-2">
                                <input
                                    type="checkbox"
                                    className="h-5 w-5"
                                    onChange={() =>
                                        setIsAgbChecked(!isAgbChecked)
                                    }
                                />
                                <p className="text-[#24245F]">
                                    {t('auth.signUp.IAccept')}
                                </p>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-center text-[#06D7A0] hover:text-[#06d79fb4]"
                                    href={'https://digitale-gruendung.de/agb/'}
                                >
                                    {t('common.termsConditions')}
                                </a>
                            </div>

                            <PrimaryButton
                                className="w-fit"
                                label={t('auth.signUp.createAccount')}
                                isSubmitting={isSubmitting}
                            />
                        </form>
                    </div>
                </div>
                <div className="flex w-full flex-col items-center justify-between gap-5 p-8 sm:w-1/2">
                    <img src={logo} alt="logo" width={170} height={50} />
                    <div className="flex flex-col gap-6">
                        <h2 className="text-center text-xl font-semibold leading-7 text-[#24245F]">
                            {t('common.welcome')}
                        </h2>
                        {/* <p className="text-center text-base leading-7 text-[#24245F]">
                            {t('auth.signUp.dontHaveAccount')}
                        </p> */}
                    </div>
                    {/* <ButtonLink
                        label={t('auth.login.signIn')}
                        href={'/sign-in'}
                    /> */}

                    <LanguageSwitcher isFullLanguage={true} />
                    <div
                        className={
                            'flex flex-col justify-center xl:flex-row xl:justify-around'
                        }
                    >
                        <a
                            className="px-8 py-3 text-center text-[#06D7A0] hover:text-[#06d79fb4]"
                            target="_blank"
                            rel="noreferrer"
                            href={'https://digitale-gruendung.de/datenschutz/'}
                        >
                            {t('common.privacyPolicy')}
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className="px-8 py-3 text-center text-[#06D7A0] hover:text-[#06d79fb4]"
                            href={'https://digitale-gruendung.de/agb/'}
                        >
                            {t('common.termsConditions')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
