import cookies from 'js-cookie';
import { AppUser } from 'src/interfaces/AppUser.interface';

export const setSession = (accessToken: string, isValid: boolean) => {
    cookies.set('__session', accessToken);
    cookies.set('is_onboarded', JSON.stringify(isValid));
};

export const setIsValidUser = (isValid: boolean) => {
    cookies.set('is_onboarded', JSON.stringify(isValid));
};

export const setUserInfo = (user: AppUser) => {
    cookies.set('user', JSON.stringify(user));
};

export const getUserInfo = () => {
    return cookies.get('user');
};

export const getSession = () => {
    const accessToken = cookies.get('__session');
    const valid = cookies.get('is_onboarded');

    let session;
    let validData;
    try {
        if (accessToken) {
            const base64Url = accessToken.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');

            session = JSON.parse(window.atob(base64));
        }
    } catch (error) {
        console.log('getSession error:', error);
    }

    try {
        if (valid) {
            validData = JSON.parse(valid);
        }
    } catch (error) {
        console.log('getValid error:', error);
    }

    return { session, accessToken, validData };
};

export const logOut = () => {
    cookies.remove('__session');
    cookies.remove('is_onboarded');
    cookies.remove('user');
};
