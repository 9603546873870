interface Language {
    [lang: string]: {
        nativeName: string;
    };
}

export const lngs: Language = {
    de: { nativeName: 'Deutsch' },
    en: { nativeName: 'English' },
};
