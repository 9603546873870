import { createContext } from 'react';

export type LanguageContent = {
    currentLanguage: string;
    setCurrentLanguage: (currentLanguage: string) => void;
};

export const LanguageContext = createContext<LanguageContent>({
    currentLanguage: 'de',
    setCurrentLanguage: () => {},
});
