import { useTranslation } from 'react-i18next';
import ButtonLink from 'src/components/buttons/ButtonLink';
import logo from 'src/assets/images/logo.svg';

const Intro = () => {
    const { t } = useTranslation();
    return (
        <div className="min-w-screen flex min-h-screen items-center justify-center bg-[#4ABFBF]">
            <div className="my-5 flex w-11/12 flex-col rounded-xl bg-[#FFF] sm:my-0 sm:flex-row lg:w-2/5">
                <div className="flex w-full flex-col items-center justify-between gap-5 p-8">
                    <img src={logo} alt="logo" width={170} height={50} />
                    <div className="flex flex-col gap-6">
                        <h2 className="text-center text-xl font-semibold leading-7 text-[#24245F]">
                            {t('auth.onboard.introTitle')}
                        </h2>
                        <p className="text-center text-base leading-7 text-[#24245F]">
                            {t('auth.onboard.introSubtitle')}
                        </p>
                    </div>
                    <ButtonLink
                        label={t('common.continue')}
                        href={'/onboard'}
                    />
                </div>
            </div>
        </div>
    );
};

export default Intro;
