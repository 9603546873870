import { useTranslation } from 'react-i18next';

type Props = {
    status: string;
};

const StatusComponent = ({ status }: Props) => {
    let className: string = '';
    let label: string = '';

    const { t } = useTranslation();
    switch (status) {
        case 'processing':
            className = 'bg-[#6495ed] text-[#6495ed] bg-opacity-20';
            label = t('common.processing');
            break;
        case 'completed':
            className = 'bg-[#06D7A0] text-[#06D7A0] bg-opacity-20';
            label = t('common.completed');
            break;
        case 'on_hold':
            className = 'bg-[#6495ed] text-[#6495ed] bg-opacity-20';
            label = t('common.onHold');
            break;
        case 'pending':
            className = 'bg-[#97b5ed] text-[#97b5ed] bg-opacity-20';
            label = t('common.pendingPayment');
            break;
        case 'failed':
            className = 'bg-[#ff6868] text-[#ff6868] bg-opacity-20';
            label = t('common.failed');
            break;
        case 'cancelled':
            className = 'bg-[#FF7E66] text-[#FF7E66] bg-opacity-20';
            label = t('common.cancelled');
            break;
        case 'refunded':
            className = ' bg-[#DEFD71] text-[#1B1C19] bg-opacity-20';
            label = t('common.refunded');
            break;
    }
    return (
        <div
            className={
                'flex w-fit items-center justify-center rounded-md px-3 py-2 ' +
                className
            }
        >
            <p className={'text-xs font-bold '}>{label}</p>
        </div>
    );
};

export default StatusComponent;
