import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_LATEST_ORDERS } from 'src/const/apiUrls';
import { client } from 'src/utils/client';
import { Link } from 'react-router-dom';
import StatusComponent from 'src/pages/orders/StatusComponent';
import { Order } from 'src/pages/orders/Orders.interface';

type Props = {};

const LatestOrders = (props: Props) => {
    const { t } = useTranslation();
    const [latestOrders, setLatestOrders] = useState<Array<Order>>([]);

    useEffect(() => {
        const fetchLatestOrders = async () => {
            try {
                const latestOrdersResp = await client.get(GET_LATEST_ORDERS);
                if (
                    latestOrdersResp &&
                    latestOrdersResp.data &&
                    latestOrdersResp.data.data
                ) {
                    setLatestOrders(latestOrdersResp.data.data);
                }
            } catch (error) {
                console.log('Latest orders couldnt load.');
            }
        };
        fetchLatestOrders();
    }, []);

    return (
        <div className="rounded-xl bg-[#fff] p-6">
            <h1 className="p-2 text-lg font-bold text-[#24245f99]">
                {t('common.orders')}
            </h1>
            <div className="flex flex-col">
                {latestOrders &&
                    latestOrders.length > 0 &&
                    latestOrders.map((item: Order) => {
                        return (
                            <div
                                key={item.id}
                                className="flex flex-col gap-2 border-b border-[#0302290d] p-4"
                            >
                                <div className="flex items-center justify-between  gap-1">
                                    <p className="text-base text-[#24245F]">
                                        <Link
                                            className="text-base font-semibold text-[#06D7A0] hover:text-[#06d79fb4]"
                                            to={'/order/' + item.id}
                                        >
                                            {t('common.order') + ' ' + item.id}
                                        </Link>
                                    </p>
                                    <p className="text-sm text-[#F80001]">
                                        {'€ ' + item.total}
                                    </p>
                                </div>
                                <div className="text-sm">
                                    <StatusComponent status={item.status} />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default LatestOrders;
