export const LOGIN =
    process.env.REACT_APP_API_LOCATION + '/wp-json/jwt-auth/v1/token';
export const SIGN_UP =
    process.env.REACT_APP_API_LOCATION +
    '/wp-json/dg/v1/dashboard/user/register';
export const CONFIRM =
    process.env.REACT_APP_API_LOCATION +
    '/wp-json/dg/v1/dashboard/user/confirm';
export const REQUEST_RESET_PASSWORD =
    process.env.REACT_APP_API_LOCATION +
    '/wp-json/dg/v1/dashboard/user/request-reset-password';
export const RESET_PASSWORD =
    process.env.REACT_APP_API_LOCATION +
    '/wp-json/dg/v1/dashboard/user/reset-password';
export const CHANGE_PASSWORD = 'user/change-password';
export const ONBOARD = 'user/onboard';
export const GET_COUNTRIES = 'user/countries';
export const GET_USER_SUMMARY = '/user/summary';
export const GET_USER = '/user';
export const GET_ALL_PRODUCTS = (language?: string) =>
    language ? 'products?lang=' + language : 'products';
export const GET_ALL_CATEGORIES = (language?: string) =>
    language ? 'products/categories?lang=' + language : 'products/categories';
export const CREATE_ORDER = 'orders';
export const FINISH_ORDER = (orderId: number) => `orders/${orderId}/complete`;
export const GET_ALL_ORDERS = 'orders';
export const GET_LATEST_ORDERS = 'orders/latest';
export const GET_PACKAGES = (language?: string) =>
    language ? 'products/packages?lang=' + language : 'products/packages';
export const GET_SEMINARS = (language?: string) =>
    language ? 'products/seminars?lang=' + language : 'products/seminars';
export const GET_COACHINGS = (language?: string) =>
    language ? 'products/coachings?lang=' + language : 'products/coachings';
