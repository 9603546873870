import {
    GridColDef,
    GridRenderCellParams,
    GridValueFormatterParams,
} from '@mui/x-data-grid';
import {
    Product,
    ProductCategory,
} from 'src/pages/marketplace/Marketplace.interface';
import BuyButton from 'src/components/buttons/BuyButton';
import i18n from 'i18next';

export const getColumns = (locale: string): GridColDef[] => {
    return [
        {
            field: 'title',
            headerName: locale === 'de' ? 'Titel' : 'Title',
            flex: 1,
            minWidth: 300,
            headerClassName: 'text-xs text-[#708191]',
        },
        {
            field: 'type',
            headerName: locale === 'de' ? 'Art' : 'Type',
            flex: 0.5,
            minWidth: 80,
            headerClassName: 'text-xs text-[#708191]',
            valueFormatter: (params: GridValueFormatterParams<any>) => {
                if (params.value == null) {
                    return '';
                }
                let temp = params.value;
                return temp ? i18n.t('page.marketplace.' + temp) : '';
            },
        },
        {
            field: 'category',
            headerName: locale === 'de' ? 'Kategorie' : 'Category',
            flex: 0.5,
            minWidth: 80,
            headerClassName: 'text-xs text-[#708191]',
            valueFormatter: (
                params: GridValueFormatterParams<Array<ProductCategory>>
            ) => {
                if (params.value == null) {
                    return '';
                }
                let temp: ProductCategory = params.value[0];
                try {
                    return temp.name;
                } catch (error) {
                    return params.value;
                }
            },
        },
        {
            field: 'price',
            headerName: locale === 'de' ? 'Preis' : 'Price',
            flex: 0.5,
            minWidth: 80,
            type: 'number',
            headerClassName: 'text-xs text-[#708191]',
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                if (params.value == null) {
                    return '';
                }
                return `€${params.value}`;
            },
        },
        {
            field: 'action',
            headerName: '',
            sortable: false,
            flex: 0.5,
            minWidth: 80,
            align: 'center',
            renderCell: (params: GridRenderCellParams<Product>) => {
                return (
                    <BuyButton
                        className="px-[20px] py-[10px] text-xs"
                        product={params.row}
                    />
                );
            },
        },
    ];
};
