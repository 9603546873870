import { createContext } from 'react';

export type MenuContent = {
    isMenuOpened: boolean;
    setIsMenuOpened: (isMenuOpened: boolean) => void;
};

export const MenuContext = createContext<MenuContent>({
    isMenuOpened: false,
    setIsMenuOpened: () => {},
});
