import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import authConfirm from 'src/assets/images/auth-confirm.svg';
import logo from 'src/assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import { CONFIRM } from 'src/const/apiUrls';

const UserConfirm = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        let savetoken = async () => {
            let token = searchParams ? searchParams.get('token') : null;
            if (token) {
                try {
                    await axios.post(CONFIRM, {
                        token,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        };
        savetoken();
    }, [searchParams]);

    return (
        <div className="min-w-screen flex min-h-screen items-center justify-center bg-[#4ABFBF]">
            <div className="my-5 flex w-11/12 flex-col items-center gap-8 rounded-xl bg-[#FFF] p-8 lg:w-3/5">
                <img src={logo} alt="logo" width={170} height={50} />
                <div className="flex flex-col items-center">
                    <h1 className="text-2xl font-semibold leading-10 text-[#24245F]">
                        {t('auth.confirmAccount.confirmSuccess')}
                    </h1>
                    <p className="text-base leading-7 text-[#24245F]">
                        {t('auth.confirmAccount.confirmSuccessText')}
                    </p>
                </div>
                <img src={authConfirm} alt="logo" width={200} height={80} />
                <PrimaryButton
                    onClick={() => navigate('/sign-in')}
                    label={t('auth.confirmAccount.goToLoginPage')}
                />
            </div>
        </div>
    );
};

export default UserConfirm;
