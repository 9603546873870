import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from 'src/components/layout/Header';
import { GET_ALL_ORDERS } from 'src/const/apiUrls';
import { client } from 'src/utils/client';
import { CircularProgress } from '@mui/material';
import StatusComponent from './StatusComponent';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Order } from './Orders.interface';

type Props = {};

const OrderDetail = (props: Props) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [orderData, setOrderData] = useState<Order>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        if (id) {
            try {
                const resp = await client.get(GET_ALL_ORDERS + '/' + id);
                if (resp && resp.data && resp.data.data) {
                    setOrderData(resp.data.data);
                    setIsLoading(false);
                }
            } catch (error) {
                console.log(error);
                toast.error('Something went wrong!');
            }
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <div className="flex flex-col gap-8">
            <Header title={t('page.orders.orderDetail')}></Header>
            {isLoading && (
                <div className="d-flex h-full w-full items-center justify-center">
                    <CircularProgress
                        style={{
                            position: 'relative',
                            top: '25vh',
                            left: '50%',
                            color: '#06D7A0',
                        }}
                    />
                </div>
            )}
            {!isLoading && orderData && (
                <div className="flex-column flex w-full flex-col gap-10 rounded-2xl bg-[#fff] px-5 py-10">
                    <div className="flex w-full flex-col gap-5 border-b pb-10">
                        <div className="flex justify-between gap-3 text-base font-semibold">
                            <p className="text-base font-semibold">
                                {t('page.orders.orderNo')}:
                                <span className="pl-3 font-semibold text-[#06D7A0]">
                                    {orderData.id}
                                </span>
                            </p>
                            <StatusComponent status={orderData.status} />
                        </div>
                        <div className="flex gap-3 text-base font-semibold">
                            <p className="font-semibold text-[#1B1C19]">
                                {t('page.orders.paymentMethod')}:
                            </p>
                            <p className="font-semibold text-[#06D7A0]">
                                {orderData.payment_method
                                    ? orderData.payment_method
                                    : ''}
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-between border-b pb-10">
                        <div className="flex gap-3 text-base font-semibold">
                            <p className="text-[#1B1C19]">
                                {t('page.orders.orderTime')}:
                            </p>
                            <p className=" text-[#06D7A0]">
                                {orderData.date_created
                                    ? new Date(
                                          orderData.date_created
                                      ).toLocaleString('de')
                                    : ''}
                            </p>
                        </div>
                        <div className="flex gap-3 text-base font-semibold">
                            <p className="text-[##1B1C19]">
                                {t('page.orders.totalAmount')}:
                            </p>
                            <p className=" text-[#06D7A0]">
                                {orderData.total ? '€' + orderData.total : ''}
                            </p>
                        </div>
                    </div>
                    {/* <div className="flex flex-col gap-10 px-3">
                        {orderData.orderLinks &&
                            orderData.orderLinks.map(
                                (item: any, index: number) => {
                                    return (
                                        <div
                                            key={item.link + index}
                                            className="flex flex-col gap-3"
                                        >
                                            <div className="flex w-full flex-col justify-between xs:flex-row">
                                                <p className="w-full break-words text-lg font-semibold text-[#1B1C19] xs:w-2/3">
                                                    {item.link}
                                                </p>
                                            </div>
                                            <div className="flex flex-col gap-3">
                                                <div className="flex flex-col gap-1 sm:flex-row">
                                                    <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                        Topic of the article
                                                    </p>
                                                    <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                        {item.topic}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col gap-1 sm:flex-row">
                                                    <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                        Anchor Tag
                                                    </p>
                                                    <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                        {item.anchorTag}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col gap-1 sm:flex-row">
                                                    <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                        URL Source
                                                    </p>
                                                    <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                        {item.urlSource}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col gap-1 sm:flex-row">
                                                    <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                        Target placement
                                                    </p>
                                                    <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                        {item.targetPlacement}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col gap-1 sm:flex-row">
                                                    <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                        Amount of words Article
                                                        needs
                                                    </p>
                                                    <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                        {item.amountOfWords}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col gap-1 sm:flex-row">
                                                    <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                        Trust links?
                                                    </p>
                                                    <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                        {item.trustLinks}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col gap-1 sm:flex-row">
                                                    <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                        Notes
                                                    </p>
                                                    <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                        {item.notes}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col gap-1 sm:flex-row">
                                                    <p className="w-full text-sm font-semibold text-[#1B1C19] sm:w-1/2">
                                                        Live link
                                                    </p>
                                                    <p className="w-full px-0 text-sm font-normal text-[#1B1C19] sm:w-1/2 sm:px-2">
                                                        {item.liveLink ? (
                                                            <a
                                                                className="text-[#06D7A0] underline"
                                                                href={
                                                                    item.liveLink
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer noopener"
                                                            >
                                                                {item.liveLink}
                                                            </a>
                                                        ) : (
                                                            'There is no live link yet'
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                    </div> */}
                </div>
            )}
        </div>
    );
};

export default OrderDetail;
