import { StepModel } from './Stepper.interface';

export const stepList: Array<StepModel> = [
    {
        id: 1,
        title: 'checkout.productList',
        subtitle: 'Add more additional information',
        isActive: true,
        divider: true,
        isFinished: false,
        isValid: false,
    },
    {
        id: 2,
        title: 'checkout.confirmation',
        subtitle: 'Summary of your order',
        isActive: false,
        divider: false,
        isFinished: false,
        isValid: false,
    },
];
