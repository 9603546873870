import { createContext } from 'react';
import { NotificationModel } from 'src/interfaces/Notification.interface';

export type NotificationContent = {
    notificationList: Array<NotificationModel>;
    setNotificationList: (notificationList: Array<NotificationModel>) => void;
};

export const NotificationContext = createContext<NotificationContent>({
    notificationList: [],
    setNotificationList: () => {},
});
