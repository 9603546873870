export interface ContactInfoModel {
    name: string;
    email: string;
    address: string;
    cardNumber: string;
    expiry: string;
    cvc: string;
    nameOnCard: string;
}

export interface PaypalModel {
    name: string;
    email: string;
    address: string;
}

export enum PaymentType {
    CreditCard = 'creditCard',
    Paypal = 'paypal',
    Invoice = 'invoice'
}

export interface CreateOrderModel {
    stripe_client_secret: string;
    total: string;
    billing_info: BillingInfo;
    failed_products: Array<any>;
    order_id: number;
}

export interface BillingInfo {
    address_1: string;
    address_2: string;
    city: string;
    company: string;
    country: string;
    first_name: string;
    last_name: string;
    postcode: string;
    state: string;
}

export interface ConfirmDialogState {
    status: ConfirmDialogStatus;
    message: string;
}
export enum ConfirmDialogStatus {
    SUCCESS = 'success',
    ERROR = 'error',
    IDLE = 'idle',
    IN_PROGRESS = 'inProgress',
}

export interface CardStatus {
    name: string;
    isComplete: boolean;
    errorMessage: string;
}
