import { Cart } from 'src/interfaces/Cart.interface';
import { Product } from 'src/pages/marketplace/Marketplace.interface';

let key: string = 'cart';

export const addToCart = (product: Product) => {
    if (typeof window !== 'undefined') {
        let tempProductList: Array<Product>;
        let storageProductList: Array<Product> | undefined = getCart();

        if (storageProductList) {
            let searchedIndex = storageProductList.findIndex(
                (item: Product) => item.id === product.id
            );
            if (searchedIndex !== -1) {
                tempProductList = [...storageProductList];
                window.localStorage.setItem(
                    key,
                    JSON.stringify(storageProductList)
                );
                return;
            }
            let tempProduct: Product = {
                ...product,
                price: Number(product.price),
            };
            tempProductList = [...storageProductList, tempProduct];
            window.localStorage.setItem(key, JSON.stringify(tempProductList));
        } else {
            let tempProduct: Product = {
                ...product,
                price: Number(product.price),
            };
            tempProductList = [tempProduct];
            window.localStorage.setItem(key, JSON.stringify(tempProductList));
        }
    }
};

export const getCart = (): Array<Product> => {
    if (typeof window !== 'undefined') {
        let localStorageString = window.localStorage.getItem(key);
        if (localStorageString) {
            let storageProductList: Array<Product> =
                JSON.parse(localStorageString);
            let tempProductList: Array<Product> = storageProductList.map(
                (item: Product) => {
                    return {
                        ...item,
                        price: Number(item.price),
                    };
                }
            );
            return tempProductList;
        }
    }
    return [];
};

export const getCartNumberSize = () => {
    const cart = getCart();
    if (cart) {
        return cart.length;
    }
    return 0;
};

export const updateCart = (productList: Array<Cart>) => {
    if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(productList));
    }
};

export const removeFromCart = (productId: number) => {
    if (typeof window !== 'undefined') {
        let storageProductList: Array<Product> | undefined = getCart();
        let tempProductList: Array<Product> = storageProductList
            ? storageProductList.filter(
                  (item: Product) => item.id !== productId
              )
            : [];
        window.localStorage.setItem(key, JSON.stringify(tempProductList));
    }
};

export const removeAllProducts = () => {
    if (typeof window !== 'undefined') {
        window.localStorage.removeItem(key);
    }
};

export const saveOrder = (orderId: number) => {
    if (typeof window !== 'undefined') {
        window.localStorage.setItem('orderId', JSON.stringify(orderId));
    }
};

export const getOrder = () => {
    if (typeof window !== 'undefined') {
        return window.localStorage.getItem('orderId');
    }
};

export const removeOrder = () => {
    if (typeof window !== 'undefined') {
        window.localStorage.removeItem('orderId');
    }
};
